const gup = (name: string, url = window.location.href) => {
  const params = new URL(url).searchParams;
  const result = params.get(name);
  if (result === null) return false;
  if (result === 'true') return true;
  if (result === 'false') return false;
  if (result === 'undefined') return undefined;
  return result === '' ? true : result;
};

export default gup;
