import { ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import cx from 'classnames';
import { usePopupState, type PopupState } from 'material-ui-popup-state/hooks';
import useSWR, { useSWRConfig } from 'swr';
import { Link } from 'wouter';
import * as appStyles from '~/app.css';
import { bold } from '~/app.css';
import Icon from '~/elements/Icon/Icon';
import { shortcutBase } from '~/elements/Icon/Icon.css';
import { Shortcut } from '~/elements/icons';
import { type DriveFile } from '~/utils/DriveAPI';
import { isBun, LEGACY, maxDepth } from '~/utils/constants';
import { concat } from '~/utils/fast';
import gup from '~/utils/gup';
import { isMarkdownLink, isMarkdownLinkTitle } from '~/utils/isMarkdownLink';
import { prefetch } from '~/utils/prefetch';
import { sort } from '~/utils/sort';
import { getId, isADocument, isAFolder, isAShortcut, rem } from '~/utils/utils';
import * as styles from './Tree.css';
import { MenuButtons, TreeMenu } from './TreeMenu';
import getAllChildren from './getAllChildren';

export const StaticTree = ({
  files,
  wiki,
}: {
  files: DriveFile[];
  wiki: DriveFile;
}) => {
  const { cache } = useSWRConfig();
  const { fontColor } = wiki.properties ?? {};
  if (files.length === 0) return null;

  return (
    <div role="tree">
      {sort(files).map((file) => {
        const externalLink = isADocument(file) ? isMarkdownLinkTitle(file.name) : undefined;
        const legacyParentId = LEGACY ? gup('p') : undefined;
        const legacyTo = LEGACY
          ? legacyParentId
            ? `/app/page/${file.id}?p=${legacyParentId}`
            : `/app/page/${file.id}`
          : '';
        const isLegacy = LEGACY;
        const destination = externalLink?.url || (isLegacy ? legacyTo : `/app/page/${wiki.id}/${file.id}`);
        return (
          <div role="treeitem" key={file.id} className={styles.linkContainer} onMouseDown={() => prefetch(file, cache)}>
            <Link
              className={styles.link}
              to={destination}
              {...(externalLink?.url && { target: '_blank' })}
              {...(isLegacy && { onClick: () => window.legacyHistory?.push(destination) })}
            >
              <Icon className={styles.icon} file={file} color={fontColor} externalLink={externalLink} />
              <span className={styles.textContainer}>
                <span className={styles.text}>{externalLink ? externalLink.name : file.name}</span>
              </span>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

type MenuState = Record<string, boolean>;

const Tree = ({
  files,
  wiki,
  wikis,
  // Re-add once react fixes
  // https://playground.react.dev/#N4Igzg9grgTgxgUxALhAMygOzgFwJYSYAEAKgBTBEQA0RaeAJkQLxUB0jtjLd3AvgEpgMBDljEAPAD4JAeil8QfIA
  // folderId = wiki.id
  folderId: _folderId,
  id: _id = _folderId,
  depth = 0,
  closedByDefault = false,
  addMenu,
  moreMenu,
  showHome = false,
}: {
  files: DriveFile[];
  wiki: DriveFile;
  wikis: DriveFile[];
  folderId?: string;
  id?: string | undefined;
  depth?: number;
  closedByDefault?: boolean;
  addMenu: PopupState;
  moreMenu: PopupState;
  showHome?: boolean;
}) => {
  // Remove once react fixes
  // https://playground.react.dev/#N4Igzg9grgTgxgUxALhAMygOzgFwJYSYAEAKgBTBEQA0RaeAJkQLxUB0jtjLd3AvgEpgMBDljEAPAD4JAeil8QfIA
  const folderId = _folderId ?? wiki.id;
  const id = _id ?? folderId;

  const { cache } = useSWRConfig();
  const { fontColor } = wiki.properties ?? {};
  const { data: menuState, mutate: mutateMenu } = useSWR<MenuState>(`/api/menu/${wiki.id}`);

  if (depth >= maxDepth) throw new Error('Max depth reached');
  if (depth === 0) {
    const activeFile = id === wiki.id ? wiki : (files.find((file) => file.id === id) ?? wiki);
    const isActive = wiki.id === activeFile.id;
    return (
      <>
        {showHome && (
          <div className={styles.linkContainer}>
            <Link
              className={cx(styles.link, {
                [bold]: isActive,
              })}
              to={`/app/page/${wiki.id}/${wiki.id}`}
              {...(isActive && { 'aria-current': 'page' })}
            >
              {wiki?.name}
            </Link>
            <MenuButtons file={wiki} wiki={wiki} addMenu={addMenu} moreMenu={moreMenu} />
          </div>
        )}
        <div role="tree">
          <Tree
            files={files}
            wiki={wiki}
            wikis={wikis}
            id={activeFile.id}
            depth={depth + 1}
            closedByDefault={closedByDefault}
            addMenu={addMenu}
            moreMenu={moreMenu}
          />
        </div>
        <TreeMenu
          activeFile={activeFile}
          files={files}
          wiki={wiki}
          addMenu={addMenu}
          moreMenu={moreMenu}
          closedByDefault={closedByDefault}
          wikis={wikis}
        />
      </>
    );
  }

  const children = sort(files.filter((file) => file.parents?.[0] === folderId));
  return (
    <>
      {children.map((file) => {
        const isOpen = menuState?.[file.id] ?? !closedByDefault;
        const isFolder = isAFolder(file);
        const hasChildren = isFolder ? files.some((o) => o.parents?.[0] === getId(file)) : false;
        const isActive = id === file.id;
        const isDuplicate = isAShortcut(file) ? files.some((o) => o.id === getId(file)) : false;
        const legacyParentId = LEGACY ? gup('p') : undefined;
        const legacyTo = LEGACY
          ? legacyParentId
            ? `/app/page/${file.id}?p=${legacyParentId}`
            : `/app/page/${file.id}`
          : '';

        if (!isDuplicate && hasChildren) {
          return (
            <div key={file.id} role="treeitem" style={{ marginLeft: depth > 0 ? '.75rem' : undefined }}>
              <div className={styles.linkContainer} style={{ margin: '.25rem 0' }}>
                <ExpandMore
                  titleAccess="Click to expand more"
                  style={{
                    transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                    marginRight: '.25rem',
                    color: fontColor ?? '#fff',
                  }}
                  onClick={(e) => {
                    if (e.shiftKey && (e.metaKey || e.ctrlKey)) {
                      const folders = files.filter(isAFolder);
                      const newMenuState = Object.fromEntries(folders.map((file) => [file.id, !isOpen]));
                      void mutateMenu({ ...menuState, ...newMenuState }, false);
                    } else if (e.shiftKey) {
                      const nestedChildren = concat([file], getAllChildren(files, file.id).filter(isAFolder));
                      const newMenuState = Object.fromEntries(nestedChildren.map((file) => [file.id, !isOpen]));
                      void mutateMenu({ ...menuState, ...newMenuState }, false);
                    } else {
                      void mutateMenu({ ...menuState, [file.id]: !isOpen }, true);
                    }
                  }}
                />
                <Link
                  className={cx(appStyles.flex, appStyles.alignCenter, {
                    [bold]: isActive,
                  })}
                  style={{
                    textDecoration: 'none',
                    position: 'relative',
                  }}
                  to={LEGACY ? legacyTo : `/app/page/${wiki.id}/${file.id}`}
                  {...(isActive && { 'aria-current': 'page' })}
                >
                  {file.name}
                  {isAShortcut(file) && (
                    <>
                      <Shortcut
                        aria-hidden="true"
                        style={{
                          marginLeft: rem(6),
                        }}
                        className={shortcutBase}
                        viewBox="0 0 257 257"
                      />
                    </>
                  )}
                </Link>
                <MenuButtons file={file} wiki={wiki} addMenu={addMenu} moreMenu={moreMenu} />
              </div>
              <Collapse in={isOpen} timeout={isBun ? 0 : 'auto'} unmountOnExit>
                <Tree
                  files={files}
                  wiki={wiki}
                  wikis={wikis}
                  id={id}
                  folderId={getId(file)}
                  depth={depth + 1}
                  addMenu={addMenu}
                  moreMenu={moreMenu}
                  closedByDefault={closedByDefault}
                />
              </Collapse>
            </div>
          );
        }

        const externalLink = isMarkdownLink(file);
        return (
          <div
            key={file.id}
            role="treeitem"
            className={styles.linkContainer}
            onMouseDown={() => prefetch(file, cache)}
            style={{ marginLeft: depth > 0 ? '.75rem' : undefined }}
          >
            <Link
              className={cx(styles.link, {
                [bold]: isActive,
              })}
              to={externalLink ? externalLink.url : LEGACY ? legacyTo : `/app/page/${wiki.id}/${file.id}`}
              {...(isActive && { 'aria-current': 'page' })}
              {...(externalLink?.url && {
                target: '_blank',
              })}
              {...(LEGACY && {
                onClick: () => {
                  window.legacyHistory?.push(externalLink ? externalLink.url : legacyTo);
                },
              })}
            >
              <Icon className={styles.icon} file={file} color={fontColor} externalLink={externalLink} sidebar />
              <span className={styles.textContainer}>
                <span className={styles.text}>{externalLink ? externalLink.name : file.name}</span>
              </span>
            </Link>
            <MenuButtons file={file} wiki={wiki} addMenu={addMenu} moreMenu={moreMenu} />
          </div>
        );
      })}
    </>
  );
};

const TreeContainer = (props: Omit<Parameters<typeof Tree>[0], 'addMenu' | 'moreMenu'>) => {
  const addMenu = usePopupState({ variant: 'popover', popupId: 'add' });
  const moreMenu = usePopupState({ variant: 'popover', popupId: 'more' });
  return <Tree {...props} addMenu={addMenu} moreMenu={moreMenu} />;
};
export default TreeContainer;
