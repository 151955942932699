import { Button } from '@mui/base/Button';
import { Add, ArrowDropDown, Close, PersonAddAlt1Outlined, Public } from '@mui/icons-material';
import { Avatar, Checkbox, FormControlLabel, List, ListItemButton, ListItemText, Menu, MenuItem } from '@mui/material';
import cx from 'classnames';
import { bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import * as app from '~/app.css';
import * as buttonStyles from '~/elements/Buttons/Button.css';
import CopyToClipboard from '~/elements/CopyToClipboard/CopyToClipboard';
import { DEV } from '~/utils/constants';
import * as DriveAPI from '~/utils/DriveAPI';
import { DriveFile, Permission, PermissionRole } from '~/utils/DriveAPI';
import { ConnectionsResponse, Contact } from '~/utils/types';
import { focusInput, ls, reg, rem, scrollToBottom, toTitleCase } from '~/utils/utils';
import { onKeyDown, Title } from '../FileModal/FileModal';
import * as treeStyles from '../Tree/Tree.css';
import * as styles from './ShareModal.css';

const contactsMenuHeight = 355;
const rolesMap = {
  owner: 'Owner',
  organizer: 'Organizer',
  fileOrganizer: 'Organizer',
  writer: 'Editor',
  commenter: 'Commenter',
  reader: 'Viewer',
} as const satisfies Record<PermissionRole, string>;

const roleDescriptions = {
  owner: 'Can edit, share, and set permissions',
  organizer: 'Can edit, share, and set permissions',
  fileOrganizer: 'Can edit, share, and set permissions',
  writer: 'Can organize, add and edit',
  commenter: 'Can comment',
  reader: 'Can read only',
} as const satisfies Record<PermissionRole, string>;

const roles = Object.entries(rolesMap)
  .filter(([key]) => key !== 'owner' && key !== 'fileOrganizer' && key !== 'organizer')
  .map(([role, label]) => ({
    role,
    label,
    description: roleDescriptions[role as PermissionRole],
  }));

type Props = {
  onCancel: (e: React.MouseEvent<HTMLButtonElement | HTMLOrSVGElement, MouseEvent>) => void;
  file: DriveFile | undefined;
  wiki: DriveFile;
  wikis: DriveFile[];
  files: DriveFile[];
  style?: React.CSSProperties;
  popover?: boolean;
};

export const ShareModal = ({ onCancel, file, wiki, wikis, files, style, popover }: Props) => {
  const [inviteOpen, setInviteOpen] = useState(false);
  const [notify, setNotify] = useState(ls.get<boolean>('wiki.notify') ?? true);
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(true);
  const [pendingPermissions, setPendingPermissions] = useState<Permission[]>([]);
  const [activePermission, setActivePermission] = useState<Permission | null>(null);
  const toBeAddedMenu = usePopupState({ variant: 'popover', popupId: 'add' });
  const accessMenu = usePopupState({ variant: 'popover', popupId: 'access' });
  const contactsMenu = usePopupState({ variant: 'popover', popupId: 'contacts' });
  const { mutate } = useSWRConfig();
  const { mutate: mutateFiles } = useSWR(`/api/files/${wiki.id}`);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: permissions = file?.permissions, mutate: mutatePermissions } = useSWR<Permission[]>(
    file ? `/drive/v3/files/${file.id}/permissions` : null,
    () => (file ? (DriveAPI.permissions.list({ id: file.id }) ?? file.permissions ?? []) : [])
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: intended
  useEffect(() => focusInput(inputRef.current), [inviteOpen]);

  // Get contacts from other wikis
  const allWikiPermissions: Contact[] =
    wikis
      ?.flatMap((wiki) => wiki.permissions ?? [])
      .filter((p): p is Extract<Permission, { emailAddress: string }> => p.type === 'user')
      .map((o) => ({
        id: o.id,
        name: o.displayName,
        email: o.emailAddress,
        photoURL: o.photoLink,
      })) ?? [];

  const token = gapi.client.getToken()?.access_token;
  const { data: contacts = [] } = useSWR<Contact[]>(token ? `/api/connections` : null, async () => {
    const res = await fetch(
      `https://people.googleapis.com/v1/people/me/connections?sortOrder=LAST_MODIFIED_ASCENDING&pageSize=1000&personFields=emailAddresses%2Cnames%2cphotos&access_token=${token}`
    );
    const data = await res.json<ConnectionsResponse>();
    if (!('connections' in data)) return [];

    // Filter out contacts without email addresses
    const contacts: Contact[] = data.connections
      .filter((o) => o.emailAddresses)
      .map((o) => ({
        id: o.resourceName,
        name:
          o.names?.find((o) => o.metadata.primary)?.displayName ??
          o.emailAddresses?.find((o) => o.metadata.primary)?.value,
        email: o.emailAddresses?.find((o) => o.metadata.primary)?.value,
        photoURL: o.photos?.find((o) => o.metadata.primary)?.url,
      }));

    const result = contacts
      .concat(allWikiPermissions)
      .filter((o, i, arr) => arr.findIndex((a) => a.email === o.email) === i);

    return result;
  });

  const filteredContacts = contacts
    .filter((contact) => !permissions?.some((p) => 'emailAddress' in p && p.emailAddress === contact.email))
    .filter((contact) => !pendingPermissions.some((p) => 'emailAddress' in p && p.emailAddress === contact.email))
    .filter(
      (contact) =>
        contact.email?.toLowerCase().includes(value.toLowerCase()) ||
        contact.name?.toLowerCase().includes(value.toLowerCase())
    )
    .sort((a, b) => {
      const valueLower = value.toLowerCase();
      const aName = a.name?.toLowerCase() ?? '';
      const bName = b.name?.toLowerCase() ?? '';
      const aEmail = a.email?.toLowerCase() ?? '';
      const bEmail = b.email?.toLowerCase() ?? '';

      const aNameStarts = aName.startsWith(valueLower);
      const bNameStarts = bName.startsWith(valueLower);
      if (aNameStarts && !bNameStarts) return -1;
      if (!aNameStarts && bNameStarts) return 1;

      const aEmailStarts = aEmail.startsWith(valueLower);
      const bEmailStarts = bEmail.startsWith(valueLower);
      if (aEmailStarts && !bEmailStarts) return -1;
      if (!aEmailStarts && bEmailStarts) return 1;

      return 0;
    });

  const fileContacts: Contact[] =
    file?.permissions
      ?.filter((p): p is Extract<Permission, { emailAddress: string }> => p.type === 'user')
      .map((p) => ({
        id: p.id,
        name: p.displayName,
        email: p.emailAddress,
        photoURL: '',
      })) ?? [];

  const emailPostfixes = allWikiPermissions
    .concat(fileContacts)
    .map((contact) => contact.email?.split('@')[1] ?? '')
    .filter((emailPostfix, i, arr) => arr.indexOf(emailPostfix) === i);

  const valuePostfix = value.split('@')[1];
  const suggestedPostfix = emailPostfixes.find((emailPostfix) =>
    valuePostfix ? emailPostfix.startsWith(valuePostfix) : false
  );
  const suggestedEmail = value.split('@')[0] + (suggestedPostfix ? `@${suggestedPostfix}` : '');

  const updatePermission = async (arg: Permission) => {
    if (!file) throw new Error('File not found');
    return mutate<DriveFile>(
      `/drive/v3/files/${file.id}`,
      async () => {
        const nextFiles = files.map((f) =>
          f.id === file.id
            ? { ...f, permissions: f.permissions?.map((p) => (p.id === arg.id ? { ...p, role: arg.role } : p)) ?? [] }
            : f
        ) satisfies DriveFile[];
        const nextPermissions = (file.permissions?.map((permission) =>
          permission.id === arg.id ? { ...permission, role: arg.role } : permission
        ) ?? []) satisfies Permission[];

        void mutateFiles(nextFiles, false);
        void mutatePermissions(nextPermissions, false);

        const res = await DriveAPI.permissions.update({
          fileId: file.id,
          permissionId: arg.id,
          role: arg.role,
        });

        return {
          ...file,
          permissions:
            file.permissions?.map((permission) =>
              permission.id === res.id ? { ...permission, role: res.role } : permission
            ) ?? [],
        };
      },
      {
        populateCache: true,
        revalidate: false,
        optimisticData: {
          ...file,
          permissions:
            file.permissions?.map((permission) =>
              permission.id === arg.id ? { ...permission, role: arg.role } : permission
            ) ?? [],
        },
      }
    );
  };

  const removePermission = async (arg: Permission) => {
    if (!file) throw new Error('File not found');
    return mutate<DriveFile>(
      `/drive/v3/files/${file.id}`,
      async () => {
        const nextFiles = files.map((f) =>
          f.id === file.id ? { ...f, permissions: f.permissions?.filter((p) => p.id !== arg.id) } : f
        );
        const nextPermissions = file.permissions?.filter((permission) => permission.id !== arg.id) ?? [];
        void mutateFiles(nextFiles, false);
        void mutatePermissions(nextPermissions, false);

        await DriveAPI.permissions.delete({
          fileId: file.id,
          permissionId: arg.id,
        });

        return {
          ...file,
          permissions: file.permissions?.filter((permission) => permission.id !== arg.id) ?? [],
        };
      },
      {
        populateCache: true,
        revalidate: false,
        optimisticData: {
          ...file,
          permissions: file.permissions?.filter((permission) => permission.id !== arg.id) ?? [],
        },
      }
    );
  };

  const addPermissions = async (pendingPermissions: Permission[]) => {
    if (!file) throw new Error('File not found');
    void mutate<DriveFile>(
      `/drive/v3/files/${file.id}`,
      async () => {
        // Initial optimistic update
        const nextFiles = files.map((f) =>
          f.id === file.id ? { ...f, permissions: [...(f.permissions ?? []), ...pendingPermissions] } : f
        );
        const nextPermissions = file.permissions ? [...file.permissions, ...pendingPermissions] : [];
        void mutateFiles(nextFiles, false);
        void mutatePermissions(nextPermissions, false);
        setPendingPermissions([]);

        const res = await Promise.allSettled(
          pendingPermissions.map((permission) =>
            DriveAPI.permissions.create({
              fileId: file.id,
              role: permission.role,
              type: permission.type,
              sendNotificationEmail: notify,
              ...('emailAddress' in permission && {
                emailAddress: permission.emailAddress,
              }),
            })
          )
        );

        const result = res.filter((r) => r.status === 'fulfilled').map((r) => r.value);
        const permissions = file.permissions ? [...file.permissions, ...result] : [];
        return {
          ...file,
          permissions,
        };
      },
      {
        populateCache: true,
        revalidate: false,
        optimisticData: {
          ...file,
          permissions: [...(file.permissions ?? []), ...pendingPermissions],
        },
      }
    );
  };

  const ButtonSubmit = (props: React.ButtonHTMLAttributes<HTMLButtonElement> = {}) => (
    <button type="submit" className={buttonStyles.primary} {...props}>
      Invite
    </button>
  );

  const isPublic = permissions?.some((p) => p.type === 'anyone') ?? false;
  const isContactsMenuOpen =
    contactsMenu.isOpen && value.length !== 0 && (filteredContacts.length === 0 ? /.+@.+/.test(value) : true);

  return (
    <div
      className={popover ? styles.popover : styles.modal}
      style={{
        zIndex: 1000,
        ...style,
      }}
    >
      <Button
        style={{
          width: 34,
          height: 34,
          position: 'absolute',
          top: -40,
          right: -4,
          padding: 0,
        }}
        className={buttonStyles.buttonCircular}
        onClick={onCancel}
      >
        <Close
          style={{
            color: '#fff',
            fontSize: 20,
          }}
        />
      </Button>
      <form
        className={styles.form}
        style={{
          paddingTop: rem(20),
          paddingBottom: rem(32),
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (pendingPermissions.length === 0) throw new Error('No pending permissions');
          if (!file) throw new Error('File not found');
          setHasSubmitted(true);
          void addPermissions(pendingPermissions);
        }}
        onKeyDown={onKeyDown}
        aria-label="form"
      >
        <div
          className="flex flex-ac flex-jc-sb"
          style={{
            gap: rem(8),
          }}
        >
          <div className="flex flex-ac">
            <Title
              style={{
                marginBottom: 2,
                fontFamily: 'var(--font-title)',
                fontWeight: 500,
                lineHeight: 1.5,
              }}
            >
              Share "{file?.name}"
            </Title>
          </div>
          <List
            style={{
              marginRight: '.5rem',
            }}
          >
            <ListItemButton
              style={{
                fontSize: 14,
                paddingLeft: rem(12),
                paddingRight: rem(28),
                borderRadius: 6,
                color: '#1b1b1b',
                fontFamily: 'var(--font-title)',
                fontWeight: 600,
                lineHeight: 1.25,
                marginTop: -1,
                marginRight: '-.5rem',
              }}
              onClick={(e) => {
                accessMenu.setAnchorEl(e.currentTarget);
                accessMenu.open();
              }}
            >
              {isPublic ? 'Public' : 'Private'}
            </ListItemButton>
            <ArrowDropDown
              style={{
                position: 'absolute',
                right: rem(-6),
                top: 0,
                bottom: 0,
                margin: 'auto',
                color: '#757575',
                pointerEvents: 'none',
              }}
            />
          </List>

          <Menu
            {...bindMenu(accessMenu)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transitionDuration={{
              enter: 200,
              exit: 0,
            }}
            open={accessMenu.isOpen}
            onClose={() => accessMenu.close()}
            slotProps={{
              paper: {
                style: {
                  color: 'inherit',
                  boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 8px 0',
                  borderRadius: 6,
                },
              },
            }}
          >
            <MenuItem
              selected={!isPublic}
              onClick={() => {
                if (!isPublic) return accessMenu.close();
                // set to private
                // remove public permission
                const p = permissions?.find((p) => p.type === 'anyone');
                if (!p) throw new Error('permission not found');
                void removePermission(p);
                accessMenu.close();
              }}
              style={{
                fontSize: 14,
                padding: `${rem(14)} ${rem(28)} ${rem(14)} ${rem(12)}`,
                color: '#1b1b1b',
                fontFamily: 'var(--font-title)',
                fontWeight: 600,
                lineHeight: 1.25,
                marginRight: '-.5rem',
              }}
            >
              <ListItemText
                slotProps={{
                  primary: {
                    style: {
                      fontFamily: 'var(--font-title)',
                      fontWeight: 600,
                    },
                  },
                  secondary: {
                    style: {
                      fontFamily: 'var(--font)',
                      letterSpacing: -0.01,
                    },
                  },
                }}
                primary="Private"
                secondary="Shared with specific people"
              />
            </MenuItem>
            <MenuItem
              selected={isPublic}
              onClick={() => {
                if (isPublic) return accessMenu.close();
                // set to public
                // add public permission
                void addPermissions([
                  {
                    id: 'anyoneWithLink',
                    type: 'anyone',
                    role: 'reader',
                  },
                ]);
                accessMenu.close();
              }}
              style={{
                fontSize: 14,
                padding: `${rem(14)} ${rem(28)} ${rem(14)} ${rem(12)}`,
                color: '#1b1b1b',
                fontFamily: 'var(--font-title)',
                fontWeight: 600,
                lineHeight: 1.25,
                marginRight: '-.5rem',
              }}
            >
              <ListItemText
                slotProps={{
                  primary: {
                    style: {
                      fontFamily: 'var(--font-title)',
                      fontWeight: 600,
                    },
                  },
                  secondary: {
                    style: {
                      fontFamily: 'var(--font)',
                      letterSpacing: -0.01,
                    },
                  },
                }}
                primary="Public"
                secondary="Anyone with the link can access. No sign-in required."
              />
            </MenuItem>
          </Menu>
        </div>
        <div
          className={cx([app.flex, app.alignCenter, app.relative])}
          style={{
            marginTop: 8,
            marginBottom: 26,
          }}
        >
          <input
            onClick={(e) => focusInput(e.target as HTMLInputElement)}
            readOnly
            value={window.location.href}
            className={treeStyles.inputReadonly}
          />
          <div
            style={{
              width: '2rem',
              height: '100%',
              position: 'absolute',
              right: 0,
            }}
          />
          <CopyToClipboard
            text={window.location.href}
            containerStyle={{
              right: 7,
              position: 'absolute',
            }}
          />
        </div>
        {permissions && (
          <h2
            className={treeStyles.subTitle}
            style={{
              marginTop: rem(10),
              marginBottom: rem(18),
            }}
          >
            WHO HAS ACCESS
          </h2>
        )}
        {permissions
          // // @ts-expect-error - testing
          // ?.concat(permissions.map((p) => ({ ...p, id: p.id + '-1' })))
          // // @ts-expect-error - testing
          // ?.concat(permissions.map((p) => ({ ...p, id: p.id + '-2' })))
          // // @ts-expect-error - testing
          // ?.concat(permissions.map((p) => ({ ...p, id: p.id + '-3' })))
          ?.sort((a) => (a.type === 'anyone' ? -1 : 0))
          .map((permission) => (
            <div
              key={permission.id}
              className="flex flex-ac flex-jc-sb"
              style={{
                marginBottom: 24,
              }}
            >
              <div
                className="flex flex-ac flex-jc"
                style={{
                  gap: rem(22),
                }}
              >
                {permission.type === 'user' && (
                  <>
                    <Avatar
                      alt={permission.displayName}
                      src={permission.photoLink}
                      style={{
                        width: 38,
                        height: 38,
                        borderRadius: 38,
                      }}
                      {...(DEV && {
                        imgProps: {
                          referrerPolicy: 'no-referrer',
                        },
                      })}
                    />
                    <div className="flex flex-col flex-jc">
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          fontFamily: 'var(--font-title)',
                          color: '#1B1B1B',
                        }}
                      >
                        {reg.regex.isEmail.test(permission.displayName) || /\s/.test(permission.displayName)
                          ? permission.displayName
                          : toTitleCase(permission.displayName)}
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          fontFamily: 'var(--font-title)',
                          color: '#A4A8B0',
                          maxWidth: rem(200),
                          paddingRight: rem(8),
                        }}
                        className={app.ellipsis}
                      >
                        {permission.emailAddress}
                      </div>
                    </div>
                  </>
                )}
                {permission.type === 'anyone' && (
                  <>
                    <Avatar
                      alt="Anyone with the link"
                      style={{
                        width: 38,
                        height: 38,
                        borderRadius: 38,
                        backgroundColor: '#638AF5',
                      }}
                    >
                      <Public />
                    </Avatar>
                    <div className="flex flex-col flex-jc">
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          fontFamily: 'var(--font-title)',
                          color: '#1B1B1B',
                        }}
                      >
                        Anyone with the link
                      </div>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          fontFamily: 'var(--font-title)',
                          color: '#A4A8B0',
                          maxWidth: rem(200),
                          paddingRight: rem(8),
                        }}
                      >
                        No sign-in required
                      </div>
                    </div>
                  </>
                )}
              </div>
              <List>
                <ListItemButton
                  disabled={
                    permission.role === 'owner' ||
                    // Disable operations while showing optimistic update
                    (permission.type === 'user' && permission.id === permission.emailAddress)
                  }
                  style={{
                    fontSize: 14,
                    paddingLeft: rem(12),
                    paddingRight: permission.role === 'owner' ? rem(22) : rem(28),
                    color: '#1b1b1b',
                    fontFamily: 'var(--font-title)',
                    fontWeight: 600,
                    lineHeight: 1.25,
                    marginTop: -1,
                    marginRight: '-.5rem',
                    borderRadius: 6,
                  }}
                  onClick={(e) => {
                    if (permission.role === 'owner') return;
                    setActivePermission(permission);
                    toBeAddedMenu.setAnchorEl(e.currentTarget);
                    toBeAddedMenu.open();
                  }}
                >
                  {rolesMap[permission.role]}
                </ListItemButton>
                {permission.role !== 'owner' &&
                  !(permission.type === 'user' && permission.id === permission.emailAddress) && (
                    <ArrowDropDown
                      style={{
                        position: 'absolute',
                        right: rem(-6),
                        top: 0,
                        bottom: 0,
                        margin: 'auto',
                        color: '#757575',
                        pointerEvents: 'none',
                      }}
                    />
                  )}
              </List>
            </div>
          ))}
        <Menu
          {...bindMenu(toBeAddedMenu)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transitionDuration={{
            enter: 200,
            exit: 0,
          }}
          open={activePermission !== null}
          onClose={() => setActivePermission(null)}
          slotProps={{
            paper: {
              style: {
                color: 'inherit',
                boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 8px 0',
                borderRadius: 6,
              },
            },
          }}
        >
          <List style={{ padding: 0 }}>
            {roles.map(({ role, label, description }) => (
              <ListItemButton
                selected={activePermission?.role === role}
                key={role}
                onClick={() => {
                  if (!activePermission) throw new Error('Active permission not found');
                  if (pendingPermissions.some((p) => p.id === activePermission.id)) {
                    setPendingPermissions(
                      pendingPermissions.map((p) => (p.id === activePermission.id ? { ...p, role } : p))
                    );
                  } else {
                    void updatePermission({ ...activePermission, role });
                  }
                  setActivePermission(null);
                }}
                style={{
                  fontSize: 14,
                  paddingLeft: rem(12),
                  paddingRight: rem(28),
                  color: '#1b1b1b',
                  fontFamily: 'var(--font-title)',
                  fontWeight: 600,
                  lineHeight: 1.25,
                  marginTop: -1,
                  marginRight: '-.5rem',
                }}
              >
                <ListItemText
                  slotProps={{
                    primary: {
                      style: {
                        fontFamily: 'var(--font-title)',
                        fontWeight: 600,
                      },
                    },
                    secondary: {
                      style: {
                        fontFamily: 'var(--font)',
                        letterSpacing: -0.01,
                      },
                    },
                  }}
                  primary={label}
                  secondary={description}
                />
              </ListItemButton>
            ))}
            <ListItemButton
              onClick={() => {
                if (!activePermission) return;
                setActivePermission(null);
                if (pendingPermissions.some((p) => p.id === activePermission.id)) {
                  setPendingPermissions(pendingPermissions.filter((p) => p.id !== activePermission.id));
                } else {
                  void removePermission(activePermission);
                }
              }}
            >
              <ListItemText
                slotProps={{
                  primary: {
                    style: {
                      fontFamily: 'var(--font-title)',
                      fontWeight: 600,
                    },
                  },
                  secondary: {
                    style: {
                      fontFamily: 'var(--font)',
                      letterSpacing: -0.01,
                    },
                  },
                }}
                primary="Remove"
                secondary="Remove this user"
              />
            </ListItemButton>
          </List>
        </Menu>
        {pendingPermissions?.length !== 0 && (
          <h4 className={treeStyles.subTitle}>
            TO BE <span style={{ letterSpacing: 1 }}>ADDED</span>
          </h4>
        )}
        {pendingPermissions.map((permission, i) => (
          <div
            key={i}
            style={{
              marginTop: 8,
              marginBottom: 24,
            }}
          >
            {permission.type === 'user' && (
              <>
                <div className="flex flex-ac flex-jc-sb">
                  <div
                    className="flex flex-ac"
                    style={{
                      gap: rem(22),
                    }}
                  >
                    <Avatar
                      alt={permission.displayName}
                      src={permission.photoLink}
                      style={{
                        width: 38,
                        height: 38,
                        borderRadius: 38,
                      }}
                      {...(DEV && {
                        imgProps: {
                          referrerPolicy: 'no-referrer',
                        },
                      })}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          fontFamily: 'var(--font-title)',
                          color: '#1B1B1B',
                        }}
                      >
                        {permission.displayName}
                      </div>
                      <div
                        className={app.ellipsis}
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          fontFamily: 'var(--font-title)',
                          color: '#A4A8B0',
                          maxWidth: rem(200),
                          paddingRight: rem(8),
                        }}
                      >
                        {permission.emailAddress}
                      </div>
                    </div>
                  </div>
                  <List>
                    <ListItemButton
                      disabled={permission.role === 'owner'}
                      style={{
                        fontSize: 14,
                        paddingLeft: rem(12),
                        paddingRight: permission.role === 'owner' ? rem(22) : rem(28),
                        borderRadius: 6,
                        color: '#1b1b1b',
                        fontFamily: 'var(--font-title)',
                        fontWeight: 600,
                        lineHeight: 1.25,
                        marginTop: -1,
                        marginRight: '-.5rem',
                      }}
                      onClick={(e) => {
                        if (permission.role === 'owner') return;
                        setActivePermission(permission);
                        toBeAddedMenu.setAnchorEl(e.currentTarget);
                        toBeAddedMenu.open();
                      }}
                    >
                      {rolesMap[permission.role]}
                    </ListItemButton>
                    {permission.role !== 'owner' && (
                      <ArrowDropDown
                        style={{
                          position: 'absolute',
                          right: rem(-6),
                          top: 0,
                          bottom: 0,
                          margin: 'auto',
                          color: '#757575',
                          pointerEvents: 'none',
                        }}
                      />
                    )}
                  </List>
                </div>
              </>
            )}
          </div>
        ))}
        {permissions && !inviteOpen && (
          <div
            style={{
              paddingTop: 16,
              paddingBottom: 8,
            }}
            className={cx(app.flex, app.justifyCenter)}
          >
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setInviteOpen(true);
                scrollToBottom(e.currentTarget.closest('form'));
              }}
              className={buttonStyles.primaryWithIcon}
            >
              <PersonAddAlt1Outlined style={{ fontSize: 20 }} />
              Invite a new user
            </button>
          </div>
        )}
        {inviteOpen && (
          <div
            style={{
              paddingTop: 4,
            }}
          >
            <div
              className="flex flex-ac"
              style={{
                position: 'relative',
                gap: '.5rem',
              }}
            >
              <input
                type="email"
                ref={inputRef}
                value={value}
                placeholder="Invite people and groups"
                className={treeStyles.modalInput}
                style={{
                  fontFamily: 'var(--font-title)',
                  fontWeight: 600,
                  borderRadius: 6,
                  paddingRight: '3.4rem',
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  setValue(value);
                  setSelected(true);
                  if (value.length === 0) {
                    contactsMenu.close();
                  } else {
                    contactsMenu.setAnchorEl(inputRef.current);
                    contactsMenu.open();
                  }
                  const { valid } = e.target.validity;
                  if (!valid && value.includes('@') && !value.startsWith('@') && e.target.value.endsWith('.')) {
                    if (isValid) setIsValid(false);
                    return;
                  }
                  setIsValid(e.target.value.includes('.') ? e.target.validity.valid : false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!isContactsMenuOpen) {
                      setInviteOpen(false);
                      setValue('');
                    }
                    contactsMenu.close();
                  }
                  if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    const firstMenuItem = document.querySelector<HTMLButtonElement>(
                      '[role="menu"] [role="button"]:first-child'
                    );
                    firstMenuItem?.focus();
                    setSelected(false);
                    return;
                  }
                  if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    const lastMenuItem = document.querySelector<HTMLButtonElement>(
                      '[role="menu"] [role="button"]:last-child'
                    );
                    lastMenuItem?.focus();
                    setSelected(false);
                    return;
                  }
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    contactsMenu.close();
                    if (isValid) {
                      const button = e.currentTarget.nextElementSibling;
                      if (!button || !(button instanceof HTMLButtonElement)) throw new Error('Button not found');
                      button.click();
                      setValue('');
                      scrollToBottom(e.currentTarget.closest('form'));
                      return;
                    }
                    const firstMenuItem = document.querySelector<HTMLButtonElement>(
                      '[role="menu"] [role="button"]:first-child'
                    );
                    if (firstMenuItem) {
                      firstMenuItem.click();
                      setValue('');
                      scrollToBottom(e.currentTarget.closest('form'));
                      return;
                    }
                  }
                }}
              />
              <button
                type="button"
                className={buttonStyles.primaryWithIcon}
                disabled={!isValid}
                style={{
                  // padding: '8px 12px', // With text
                  padding: '8px 9px',
                  borderRadius: 6,
                  position: 'absolute',
                  right: 8,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  const newPermission: Permission = {
                    id: value,
                    role: 'writer',
                    type: 'user',
                    displayName: value,
                    emailAddress: value,
                    photoLink: '',
                  };
                  setPendingPermissions((permissions) => [...permissions, newPermission]);
                  setIsValid(false);
                  setValue('');
                  focusInput(inputRef.current);
                }}
              >
                <Add style={{ fontSize: 20 }} />
              </button>
              <Menu
                {...bindMenu(contactsMenu)}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                transitionDuration={{
                  enter: 200,
                  exit: 0,
                }}
                anchorEl={inputRef.current}
                open={isContactsMenuOpen}
                onClose={() => contactsMenu.close()}
                anchorOrigin={{
                  // Show below input if there's enough space, otherwise show above
                  get vertical() {
                    const bottom = inputRef.current?.getBoundingClientRect().bottom ?? 0;
                    if (bottom + contactsMenuHeight + 14 < document.body.clientHeight) return 'bottom';
                    return 'top'; // Anchor at the top of the input (shows above input)
                  },
                  horizontal: 'left',
                }}
                transformOrigin={{
                  // Show below input if there's enough space, otherwise show above
                  get vertical() {
                    const bottom = inputRef.current?.getBoundingClientRect().bottom ?? 0;
                    if (bottom + contactsMenuHeight + 14 < document.body.clientHeight) return 'top';
                    return 'bottom'; // Transform from the bottom of the menu (shows above input)
                  },
                  horizontal: 'left',
                }}
                slotProps={{
                  paper: {
                    style: {
                      minWidth: inputRef.current?.offsetWidth,
                      color: 'inherit',
                      boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 8px 0',
                      borderRadius: 6,
                      maxHeight: contactsMenuHeight,
                    },
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    inputRef.current?.focus();
                  }
                }}
              >
                {/* Email */}
                {filteredContacts.length === 0 && /.+@.+/.test(value) && (
                  <ListItemButton
                    selected={isValid || suggestedEmail.length > value.length}
                    style={{
                      gap: rem(22),
                      padding: `${rem(6)} ${rem(16)}`,
                    }}
                    onClick={() => {
                      const email = value.length > suggestedEmail.length ? value : suggestedEmail;
                      const newPermission: Permission = {
                        id: email,
                        role: 'writer',
                        type: 'user',
                        displayName: email,
                        emailAddress: email,
                        photoLink: '',
                      };
                      setPendingPermissions((permissions) => [...permissions, newPermission]);
                      contactsMenu.close();
                      setValue('');
                    }}
                  >
                    <Avatar
                      style={{
                        width: 38,
                        height: 38,
                        borderRadius: 38,
                        backgroundColor: '#638AF5',
                      }}
                      {...(DEV && {
                        imgProps: {
                          referrerPolicy: 'no-referrer',
                        },
                      })}
                    >
                      {value.charAt(0).toUpperCase()}
                    </Avatar>
                    <ListItemText
                      primary={value.length > suggestedEmail.length ? value : suggestedEmail}
                      secondary={value.length > suggestedEmail.length ? value : suggestedEmail}
                      slotProps={{
                        primary: {
                          style: {
                            fontFamily: 'var(--font-title)',
                            fontWeight: 600,
                            fontSize: 16,
                          },
                        },
                        secondary: {
                          style: {
                            fontFamily: 'var(--font-title)',
                            fontWeight: 500,
                            fontSize: 14,
                            color: '#A4A8B0',
                          },
                        },
                      }}
                    />
                  </ListItemButton>
                )}

                {/* Contacts */}
                {filteredContacts?.map((contact, i) => (
                  <ListItemButton
                    style={{
                      gap: rem(22),
                      padding: `${rem(6)} ${rem(16)}`,
                    }}
                    selected={i === 0 && selected}
                    key={contact.id}
                    {...(i === 0 &&
                      ({
                        onKeyDown: (e) => {
                          if (filteredContacts.length === 1) {
                            if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return;
                          } else if (e.key !== 'ArrowUp') return;

                          e.preventDefault();
                          e.stopPropagation();
                          const prevSibling = e.currentTarget.previousElementSibling;
                          if (!prevSibling) {
                            inputRef.current?.focus();
                          }
                        },
                      } satisfies { onKeyDown: React.HTMLAttributes<HTMLDivElement>['onKeyDown'] }))}
                    {...(i !== 0 &&
                      i === filteredContacts.length - 1 &&
                      ({
                        onKeyDown: (e) => {
                          if (e.key !== 'ArrowDown') return;
                          e.preventDefault();
                          e.stopPropagation();
                          const nextSibling = e.currentTarget.nextElementSibling;
                          if (!nextSibling) {
                            inputRef.current?.focus();
                          }
                        },
                      } satisfies { onKeyDown: React.HTMLAttributes<HTMLDivElement>['onKeyDown'] }))}
                    onClick={() => {
                      const newPermission: Permission = {
                        id: contact.id,
                        role: 'writer',
                        type: 'user',
                        displayName: contact.name ?? contact.email ?? '',
                        emailAddress: contact.email ?? '',
                        photoLink: contact.photoURL ?? '',
                      };
                      setPendingPermissions((permissions) => [...permissions, newPermission]);
                      contactsMenu.close();
                      setValue('');
                    }}
                  >
                    <Avatar
                      alt={contact.name ?? ''}
                      src={contact.photoURL ?? ''}
                      style={{
                        width: 38,
                        height: 38,
                        borderRadius: 38,
                      }}
                      {...(DEV && {
                        imgProps: {
                          referrerPolicy: 'no-referrer',
                        },
                      })}
                    >
                      {contact.name?.charAt(0).toUpperCase()}
                    </Avatar>
                    <ListItemText
                      primary={
                        contact.name
                          ? reg.regex.isEmail.test(contact.name) || /\s/.test(contact.name)
                            ? contact.name
                            : toTitleCase(contact.name)
                          : ''
                      }
                      secondary={contact.email}
                      slotProps={{
                        primary: {
                          style: {
                            fontFamily: 'var(--font-title)',
                            fontWeight: 600,
                            fontSize: 16,
                          },
                        },
                        secondary: {
                          style: {
                            fontFamily: 'var(--font-title)',
                            fontWeight: 500,
                            fontSize: 14,
                            color: '#A4A8B0',
                          },
                        },
                      }}
                    />
                  </ListItemButton>
                ))}
              </Menu>
            </div>
            <div
              className="flex flex-ac flex-jc-sb"
              style={{
                paddingTop: 34,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notify}
                    onChange={() => {
                      setNotify(!notify);
                      ls.set<boolean>('wiki.notify', !notify);
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      fontSize: 14,
                      fontFamily: 'var(--font-title)',
                      color: '#1b1b1b',
                      fontWeight: 600,
                    }}
                  >
                    Notify people
                  </span>
                }
              />
              <div className="flex">
                <button
                  type="button"
                  onClick={(e) => {
                    setInviteOpen(false);
                    setIsValid(false);
                    setPendingPermissions([]);
                    setHasSubmitted(false);
                    setValue('');
                    contactsMenu.close();
                    if (hasSubmitted) {
                      onCancel(e);
                    }
                  }}
                  style={{
                    marginRight: 6,
                    color: '#5f6368',
                    borderRadius: 8,
                  }}
                  className={buttonStyles.transparent}
                >
                  {hasSubmitted ? 'Close' : 'Cancel'}
                </button>
                <ButtonSubmit className={buttonStyles.primaryWithIcon} disabled={pendingPermissions.length === 0} />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
