// @ts-nocheck - disable type checking for this file
/* eslint-disable */

// PrevNext uses findLast
// https://caniuse.com/mdn-javascript_builtins_array_findlast
// https://chatgpt.com/c/22aad267-e258-4b13-8a19-e09bda022942
if (!Array.prototype.findLast) {
  Array.prototype.findLast = function (callback) {
    // Ensure that the 'this' value is not null or undefined
    if (this == null) {
      throw new TypeError('this is null or not defined');
    }
    // Ensure that callback is a function
    if (typeof callback !== 'function') {
      throw new TypeError('callback must be a function');
    }

    const arr = Object(this);
    const len = arr.length >>> 0;

    // Iterate backwards through the array
    for (let i = len - 1; i >= 0; i--) {
      if (callback(arr[i], i, arr)) {
        return arr[i];
      }
    }

    // Return undefined if no element satisfies the callback
    return undefined;
  };
}

if (!Array.prototype.toSorted) {
  Array.prototype.toSorted = function (compareFunction) {
    return [...this].sort(compareFunction);
  };
}

if (!window.queueMicrotask) {
  window.queueMicrotask = (callback) => Promise.resolve().then(callback);
}

// Structured clone
// if (!window.structuredClone) {
//   window.structuredClone = (obj) => JSON.parse(JSON.stringify(obj));
// }
