import './polyfills.ts'; // must load first

// Disabled: https://github.com/facebook/react/issues/29915
// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { SWRConfig, type Cache, type SWRConfiguration } from 'swr';
import App from './App.tsx';
import Login from './Login.tsx';
import './index.css';

// Legacy
import r2wc from '@r2wc/react-to-web-component';
import { dequal } from 'dequal/lite';
import { Route, Switch } from 'wouter';
import LastModified from './components/LastModified/LastModified.tsx';
import PrevNext from './components/PrevNext/PrevNext.tsx';
import Search from './components/Search/Search.tsx';
import { StaticTree } from './components/Tree/Tree.tsx';
import WikiSelector from './components/WikiSelector/WikiSelector.tsx';
import Breadcrumbs from './elements/Breadcrumbs/Breadcrumbs.tsx';
import Icon from './elements/Icon/Icon.tsx';
import PoweredBy from './elements/PoweredBy/PoweredBy.tsx';
import Title from './elements/Title/Title.tsx';
import { lightTheme } from './theme.css.ts';
import { type DriveFile, type ExportResult } from './utils/DriveAPI.ts';
import { cacheFlag, DEV, expiredToken, host, isIOS, LEGACY, MODERN, protocol } from './utils/constants.ts';
import type { Profile } from './utils/types.ts';
import { get } from './utils/utils.ts';
import * as wcStyles from './wc.css.ts';

if (DEV) {
  await import('./utils/runtime-error-overlay.ts');
  if (import.meta.hot) {
    import.meta.hot?.on('vite:beforeUpdate', () => {
      console.clear();
    });
  }
}

const createCacheProvider = async () => {
  const cacheName = 'app';
  const cacheKey = 'wiki';
  if (DEV && !cacheFlag) {
    const cache = await caches.open(cacheName);
    await cache.delete(cacheKey);
  }

  // When testing locally using http for non-localhost URL's, the cache is disabled in the browser.
  const isHttpDev = DEV && protocol === 'http:' && host !== 'localhost';
  const cache = isHttpDev
    ? { match: () => ({ json: () => {} }), put: () => {}, delete: () => {} }
    : await caches.open(cacheName);

  // When initializing, we restore the data from the Cache Storage into a map.
  const json = ((await (await cache.match(cacheKey))?.json()) ?? []) as [string, string][];
  const map = new Map<string, unknown>(json);
  if (DEV) window.cache = map;

  let unloaded = false;
  const saveCache = async () => {
    if (unloaded) return;
    unloaded = true;

    for (const [key, value] of map.entries()) {
      const val = get<{ data: ExportResult }>(value);
      const data = val?.data;
      const url = data?.url;
      // Don't store blob urls in cache
      if (val && url?.startsWith('blob:')) {
        map.set(key, { ...val, data: { ...data, url: undefined } });
      }
      // Don't persist menu state
      if (key.startsWith('/api/menu/')) {
        map.delete(key);
      }
    }

    // Debugging: expired token
    if (DEV && expiredToken) {
      const user = map.get('/api/user/me');
      const val = get<{ data: Profile }>(user);
      if (val) {
        map.set('/api/user/me', {
          ...val,
          data: {
            ...val.data,
            tokens: {
              accessToken: 'expired',
              expires: 0,
              expiresAt: Date.now(),
            },
          },
        });
      }
    }

    const appCache = JSON.stringify(Array.from(map.entries()));
    await cache.put(cacheKey, new Response(appCache));
  };

  // https://github.com/segmentio/analytics-next/blob/master/packages/browser/src/lib/on-page-change.ts
  if (isIOS) {
    window.addEventListener('pagehide', saveCache);
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') void saveCache();
      else unloaded = false;
    });
  }
  window.addEventListener('beforeunload', saveCache);
  return () => map as Cache;
};

const cacheProvider = typeof window.caches === 'undefined' ? undefined : await createCacheProvider();
const config: SWRConfiguration = {
  ...(cacheProvider && { provider: cacheProvider }),
  compare: dequal,
  revalidateOnMount: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  focusThrottleInterval: 1000,
  onError: (err) => {
    throw err;
  },
};

// web components for legacy
if (LEGACY) {
  type Props = Parameters<typeof Search>[0];

  const SearchWC = (props: Props) => (
    <SWRConfig value={config}>
      <div className={lightTheme} data-theme="light">
        <Search {...props} />
      </div>
    </SWRConfig>
  );
  const searchComponentName = 'wc-search';
  if (!customElements.get(searchComponentName)) {
    customElements.define(
      searchComponentName,
      r2wc(SearchWC, {
        props: {
          files: 'json',
          wiki: 'json',
        },
      })
    );
  }

  const titleComponentName = 'wc-title';
  const TitleWC = (props: { title: string }) => (
    <div className={lightTheme} data-theme="light">
      <div className={wcStyles.titleContainer}>
        <Title className={wcStyles.title}>{props.title}</Title>
      </div>
    </div>
  );
  if (!customElements.get(titleComponentName)) {
    customElements.define(titleComponentName, r2wc(TitleWC, { props: { title: 'string' } }));
  }

  const lastModifiedName = 'wc-last-modified';
  const LastModifiedWC = ({ content, file }: { content?: string; file: DriveFile }) => (
    <div className={lightTheme} data-theme="light">
      <LastModified file={file} content={content} />
    </div>
  );
  if (!customElements.get(lastModifiedName)) {
    customElements.define(lastModifiedName, r2wc(LastModifiedWC, { props: { content: 'string', file: 'json' } }));
  }

  const breadcrumbsName = 'wc-breadcrumbs';
  const BreadcrumbsWC = ({ file, files, wiki }: { file: DriveFile; files: DriveFile[]; wiki: DriveFile }) => (
    <div className={lightTheme} data-theme="light">
      <Breadcrumbs files={files} file={file} wiki={wiki} />
    </div>
  );
  if (!customElements.get(breadcrumbsName)) {
    customElements.define(
      breadcrumbsName,
      r2wc(BreadcrumbsWC, { props: { file: 'json', files: 'json', wiki: 'json' } })
    );
  }

  const prevNextName = 'wc-prev-next';
  const PrevNextWC = ({ file, files, wiki }: { file: DriveFile; files: DriveFile[]; wiki: DriveFile }) => (
    <SWRConfig value={config}>
      <div className={lightTheme} data-theme="light">
        <PrevNext file={file} files={files} wiki={wiki} />
      </div>
    </SWRConfig>
  );
  if (!customElements.get(prevNextName)) {
    customElements.define(prevNextName, r2wc(PrevNextWC, { props: { file: 'json', files: 'json', wiki: 'json' } }));
  }

  const wikiSelectorName = 'wc-wiki-selector';
  const WikiSelectorWC = ({ wiki, wikis }: { wiki: DriveFile; wikis: DriveFile[] | undefined }) => (
    <div className={lightTheme} data-theme="light">
      <WikiSelector wiki={wiki} wikis={wikis} />
    </div>
  );
  if (!customElements.get(wikiSelectorName)) {
    customElements.define(wikiSelectorName, r2wc(WikiSelectorWC, { props: { wiki: 'json', wikis: 'json' } }));
  }

  const poweredByName = 'wc-powered-by';
  const PoweredByWC = () => (
    <div className={lightTheme} data-theme="light">
      <PoweredBy />
    </div>
  );

  if (!customElements.get(poweredByName)) {
    customElements.define(poweredByName, r2wc(PoweredByWC, {}));
  }

  const iconName = 'wc-icon';
  const IconWC = (props: {
    file: DriveFile;
    color: string;
    hasChildren: boolean;
    sidebar: boolean;
    bgColor?: string;
  }) => {
    return (
      <div className={lightTheme} data-theme="light">
        <Icon {...props} />
      </div>
    );
  };
  if (!customElements.get(iconName)) {
    customElements.define(
      iconName,
      r2wc(IconWC, {
        props: { file: 'json', color: 'string', hasChildren: 'boolean', sidebar: 'boolean', bgColor: 'string' },
      })
    );
  }

  const staticTreeName = 'wc-static-tree';
  const staticTreeWC = (props: { files: DriveFile[]; folderId: string; wiki: DriveFile }) => (
    <div className={lightTheme} data-theme="light">
      <StaticTree {...props} />
    </div>
  );

  if (!customElements.get(staticTreeName)) {
    customElements.define(
      staticTreeName,
      r2wc(staticTreeWC, {
        props: { files: 'json', folderId: 'string', wiki: 'json' },
      })
    );
  }

  // const pageName = 'wc-page';
  // const PageWC = (props: PropsOf<typeof Page>) => (
  //   <div className={lightTheme} data-theme="light">
  //     <Page {...props} />
  //   </div>
  // );
  // if (customElements.get(pageName)) {
  //   customElements.define(
  //     pageName,
  //     r2wc(PageWC, {
  //       props: {
  //         content: 'string',
  //         height: 'string',
  //         file: 'json',
  //         indexPage: 'json',
  //         isLoading: 'boolean',
  //         url: 'string',
  //       },
  //     })
  //   );
  // }
}

if (MODERN) {
  const root = document.getElementById('root');
  if (!root) throw new Error('Root element not found');
  createRoot(root).render(
    // Disabled: https://github.com/facebook/react/issues/29915
    // <StrictMode>
    <SWRConfig value={config}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/auth" component={Login} />
        <Route path="*" component={App} />
      </Switch>
    </SWRConfig>
    // </StrictMode>
  );
}
