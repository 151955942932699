import cx from 'classnames';
import { darkTheme, lightTheme } from '~/theme.css';
import { useTheme } from './utils/hooks';
import { getAuthURL } from './utils/utils';

const redirect = window.location.origin;
const url = getAuthURL(redirect);

function Login() {
  const theme = useTheme()[0];
  return (
    <>
      <div
        className={cx({
          [darkTheme]: theme === 'dark',
          [lightTheme]: theme === 'light',
        })}
      >
        <a href={url}>Login</a>
      </div>
    </>
  );
}

export default Login;
