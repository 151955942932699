import { Button } from '@mui/base/Button';
import {
  Add,
  AddLinkOutlined,
  AddToDrive,
  ArrowDownward,
  ArrowUpward,
  CreateNewFolderOutlined,
  DriveFileRenameOutline,
  MoreHoriz,
  PersonAddAlt1Outlined,
  Replay,
  SettingsOutlined,
  SubdirectoryArrowRight,
  VerticalAlignBottom,
  VerticalAlignTop,
} from '@mui/icons-material';
import { Backdrop, Divider, Menu, MenuItem, Modal } from '@mui/material';
import cx from 'classnames';
import { bindMenu, type PopupState } from 'material-ui-popup-state/hooks';
import { useEffect, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { P, match } from 'ts-pattern';
import { Link, useLocation } from 'wouter';
import { DriveCopy, DriveMove, Trash } from '~/elements/icons';
import { darkTheme, lightTheme } from '~/theme.css';
import * as DriveAPI from '~/utils/DriveAPI';
import { type DriveFile } from '~/utils/DriveAPI';
import { DrivePicker, PickerAction } from '~/utils/DrivePicker';
import { DEV, isBun, mimeTypes, newFileId } from '~/utils/constants';
import { getPrev } from '~/utils/getPrev';
import { useAuth, useKeyPress, useOpenId, useTheme } from '~/utils/hooks';
import { matchPath } from '~/utils/make-matcher';
import { sort } from '~/utils/sort';
import { getId, getMimeTypeName, getNewFile, isAFolder } from '~/utils/utils';
import { FileModal } from '../FileModal/FileModal';
import { ShareModal } from '../ShareModal/ShareModal';
import { NestedMenuItem } from './NestedMenuItem';
import * as styles from './Tree.css';
import getAllAncestors from './getAllAncestors';
import getMovedFiles, { Direction } from './getMovedFiles';

type MenuState = Record<string, boolean>;
type PageModalType = 'document' | 'sheet' | 'slide' | 'form' | 'folder';
type ModalType = 'rename' | 'share' | 'trash' | 'copy' | 'link' | 'shortcut' | 'move' | PageModalType;
const pageModalTypes = ['document', 'sheet', 'slide', 'form', 'folder'] satisfies PageModalType[];
const pageTypes = {
  document: 'application/vnd.google-apps.document',
  sheet: 'application/vnd.google-apps.spreadsheet',
  slide: 'application/vnd.google-apps.presentation',
  form: 'application/vnd.google-apps.form',
  folder: 'application/vnd.google-apps.folder',
} as const satisfies Record<PageModalType, DriveAPI.DriveMimeType>;

export const MenuButtons = ({
  file,
  wiki,
  addMenu,
  moreMenu,
}: {
  file: DriveFile;
  wiki: DriveFile;
  addMenu: PopupState;
  moreMenu: PopupState;
}) => {
  const { fontColor } = wiki.properties ?? {};
  const [openId, setOpenId] = useOpenId();

  return (
    <>
      <div
        className={styles.menuContainer}
        style={{
          display: openId === file.id ? 'flex' : undefined,
        }}
      >
        <Button
          data-icon="add"
          onClick={(e) => {
            setOpenId(file.id);
            addMenu.setAnchorEl(e.currentTarget);
            addMenu.open();
          }}
        >
          <Add htmlColor={fontColor ?? ''} />
        </Button>
        <Button
          data-icon="more"
          onClick={(e) => {
            setOpenId(file.id);
            moreMenu.setAnchorEl(e.currentTarget);
            moreMenu.open();
          }}
        >
          <MoreHoriz htmlColor={fontColor ?? ''} />
        </Button>
      </div>
    </>
  );
};

/**
 * Handles the menus for the tree.
 * @param props
 * @param props.activeFile - The file that is currently highlighted in the tree, used for keyboard shortcuts
 */
export const TreeMenu = ({
  activeFile,
  files,
  wiki,
  wikis,
  addMenu,
  moreMenu,
  closedByDefault,
}: {
  activeFile: DriveFile | undefined;
  files: DriveFile[];
  wiki: DriveFile;
  wikis: DriveFile[];
  addMenu: PopupState;
  moreMenu: PopupState;
  closedByDefault: boolean;
}) => {
  const { user } = useAuth();
  const [theme] = useTheme();
  const [openId, setOpenId] = useOpenId();
  const [modal, setModal] = useState<ModalType | undefined>();
  const [modalId, setModalId] = useState<string | undefined>();
  const [input, setInput] = useState<string | undefined>();
  const [linkURL, setLinkURL] = useState<string | undefined>();
  const { cache, mutate } = useSWRConfig();
  const mutateFile = (file: DriveFile & Parameters<typeof DriveAPI.update>[0]) =>
    void mutate(
      `/drive/v3/files/${file.id}`,
      async () => {
        // Make request
        const { id, properties } = file;
        // todo: once getMovedFiles() returns null for deleted indexes instead of undefined, this can be deleted
        if (properties && 'index' in properties && properties.index === undefined) {
          properties.index = null;
        }
        const response = await DriveAPI.update({
          id,
          name: file.name,
          properties,
          ...(file.explicitlyTrashed && { trashed: file.explicitlyTrashed }),
          ...(file.trashed && { trashed: file.trashed }),
          ...(file.addParents && { addParents: file.addParents }),
          ...(file.removeParents && { removeParents: file.removeParents }),
        });
        void mutate(`/drive/v3/files/${id}`, response, false);
        return response;
      },
      {
        optimisticData: {
          ...file,
          modifiedTime: new Date().toISOString(),
        },
        populateCache: true,
        revalidate: false,
      }
    );
  const { mutate: mutateFiles } = useSWR(`/api/files/${wiki.id}`);
  const { data: menuState, mutate: mutateMenu } = useSWR<MenuState>(`/api/menu/${wiki.id}`);
  const navigate = useLocation()[1];

  // The file that is open in the modal
  const openFile =
    openId === wiki.id || modalId === wiki.id ? wiki : files.find((o) => o.id === openId || o.id === modalId);
  const siblings = files.filter((f) => f.parents?.[0] === openFile?.parents?.[0]);
  const fileIndex = sort(siblings).findIndex((o) => o.id === openId);

  useKeyPress(['ArrowUp', 'ArrowDown'], (e) => {
    if (!e.shiftKey || !e.ctrlKey) return;
    if (!activeFile) throw new Error('File not found');
    if (activeFile.id === wiki.id) return;
    if (modal || openId) return;
    const { nextFile, nextSiblingFile, nextFiles } = getMovedFiles(
      files,
      activeFile,
      e.key === 'ArrowUp' ? 'up' : 'down'
    );

    // Update files
    void mutateFile(nextFile);
    if (nextSiblingFile) void mutateFile(nextSiblingFile);
    void mutateFiles(nextFiles, false);
  });

  useKeyPress('.', () => {
    if (!activeFile) return;
    if (modal || openId) return;
    if (activeFile.id === newFileId) {
      throw new Error('Attempted to open a file with the id "new"');
    }
    setOpenId(activeFile.id);
    queueMicrotask(() => {
      const activeMenuItem = document.querySelector('[aria-current="page"]')?.parentElement;
      const moreIcon = activeMenuItem?.querySelector('[data-icon="more"]');
      if (!moreIcon) throw new Error('More icon not found');
      moreMenu.setAnchorEl(moreIcon);
      moreMenu.open();
    });
  });

  useKeyPress('a', () => {
    if (!activeFile) return;
    if (modal || openId) return;
    setOpenId(activeFile.id);
    queueMicrotask(() => {
      const activeMenuItem = document.querySelector('[aria-current="page"]')?.parentElement;
      const addIcon = activeMenuItem?.querySelector('[data-icon="add"]');
      if (!addIcon) throw new Error('Add icon not found');
      addMenu.setAnchorEl(addIcon);
      addMenu.open();
    });
  });

  // open close folders
  useKeyPress(' ', (e) => {
    if (!activeFile) return;
    if (modal || openId) return;
    if (!isAFolder(activeFile)) return;
    const hasChildren = files.some((o) => o.parents?.[0] === getId(activeFile));
    if (!hasChildren) return;
    e.preventDefault();
    const isOpen = menuState?.[activeFile.id] ?? !closedByDefault;
    void mutateMenu({ ...menuState, [activeFile.id]: !isOpen }, false);
  });

  // determines whether or not a files ancestors should be open
  const parent = activeFile?.parents?.[0];
  useEffect(() => {
    if (!parent) return;
    const ancestors = getAllAncestors(files, parent);
    const newMenu = Object.fromEntries(ancestors.map((o) => [o.id, true]));
    if (Object.keys(newMenu).length === 0) return;
    void mutateMenu((prev) => ({ ...prev, ...newMenu }), false);
  }, [parent, files, mutateMenu]);

  const closeMenus = () => {
    addMenu.close();
    moreMenu.close();
    setOpenId('');
  };

  const openModal = (type: ModalType) => {
    setModalId(openId);
    closeMenus();
    if (type === 'shortcut' || type === 'move') return;
    if (type === 'rename') setInput(openFile?.name);
    setModal(type);
    focusInput();
  };

  const closeModal = () => {
    setModal(undefined);
    setModalId(undefined);
    setInput('');
  };

  const move = (direction: Direction) => {
    if (!openFile) throw new Error('File not found');
    const { nextFile, nextSiblingFile, nextFiles } = getMovedFiles(files, openFile, direction);
    void mutateFile(nextFile);
    if (nextSiblingFile) void mutateFile(nextSiblingFile);
    void mutateFiles(nextFiles, false);
    closeMenus();
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const focusInput = () =>
    queueMicrotask(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!openFile) throw new Error('File not found');
    if (!modal) throw new Error('Modal not found');
    if (modal === 'shortcut' || modal === 'move' || modal === 'share') return;
    if (modal === 'rename') {
      if (!input) throw new Error('Input is empty');
      void mutateFile({ ...openFile, name: input });
      void mutateFiles(
        files.map((o) => (o.id === openFile.id ? { ...o, name: input } : o)),
        false
      );
    } else if (modal === 'copy') {
      const name = `Copy of ${openFile.name}`;
      if (!openFile.parents) throw new Error('Parents not found');
      void mutateFiles(
        DriveAPI.copy({
          id: openFile.id,
          name,
          parents: openFile.parents,
        }).then((file) => {
          const { params } = matchPath('/app/page/:wiki/:child');
          // mutateFile(res);
          void mutate(`/drive/v3/files/${file.id}`, file, false);
          const cachedExport = cache.get(`/drive/v3/files/${openFile.id}/export`);
          void mutate(`/drive/v3/files/${file.id}/export`, cachedExport?.data ?? { html: '' }, false);
          if (params?.child === newFileId) {
            navigate(`/app/page/${wiki.id}/${file.id}`, {
              replace: true,
            });
          }
          return [...files, file];
        }),
        {
          revalidate: false,
          populateCache: true,
          optimisticData: [...files, { ...openFile, name, id: newFileId }],
        }
      );
    } else if (modal === 'trash') {
      const prev = getPrev({
        file: openFile,
        files,
        wiki,
      });
      mutateFile({ ...openFile, explicitlyTrashed: true });
      void mutateFiles(
        files.filter((o) => o.id !== openFile.id),
        false
      );
      navigate(prev ? `/app/page/${wiki.id}/${prev.id}` : `/app/page/${wiki.id}/${wiki.id}`);
    }
    // Page modals
    else if (modal === 'link' || pageModalTypes.includes(modal)) {
      const parents = isAFolder(openFile) ? ([getId(openFile)] satisfies [string]) : openFile.parents;
      if (!parents) throw new Error('Parents not found');
      const mimeType = modal === 'link' ? mimeTypes.document : pageTypes[modal];
      const name =
        modal === 'link'
          ? `[${input?.trim() || linkURL}](${linkURL})`
          : input?.trim()
            ? input
            : `Untitled ${getMimeTypeName(mimeType)}`;
      const newFile = getNewFile({
        name,
        mimeType,
        parents,
        user,
        files,
      });
      if (!newFile.parents) throw new Error('Parents not found');
      void mutateFiles(
        DriveAPI.create({
          name: newFile.name,
          mimeType: newFile.mimeType,
          parents: newFile.parents,
        }).then(async (res) => {
          const { params } = matchPath('/app/page/:wiki/:child');
          void mutate(`/drive/v3/files/${res.id}`, res, false);
          if (params?.child === newFileId) {
            navigate(`/app/page/${wiki.id}/${res.id}`, {
              replace: true,
            });
          }
          return [...files, res];
        }),
        {
          revalidate: false,
          populateCache: true,
          optimisticData: [...files, newFile],
        }
      );
      void navigate(`/app/page/${wiki.id}/${newFileId}`);
    }
    closeModal();
  };

  const handleAction = (modal: 'move' | 'shortcut', data: PickerAction) => {
    if (data.action !== 'picked') return;
    const file = data.docs[0];
    if (!file) throw new Error('File not found');
    if (!openFile) throw new Error('Open file not found');
    if (modal === 'move') {
      void mutateFile({
        ...openFile,
        parents: [file.id],
        addParents: file.id,
        ...(openFile.parents?.[0] && {
          removeParents: openFile.parents[0],
        }),
      });
      const nextFiles = files.map((o) => {
        if (o.id === openId) return { ...o, parents: [file.id] };
        return o;
      });
      void mutateFiles(nextFiles, false);
    } else if (modal === 'shortcut') {
      const parents = isAFolder(openFile) ? ([openFile.id] satisfies [string]) : openFile?.parents;
      if (!parents) throw new Error('Parents not found');
      void mutateFiles(
        DriveAPI.create({
          name: file.name,
          mimeType: mimeTypes.shortcut,
          parents,
          shortcutDetails: {
            targetId: file.id,
          },
        }).then((res) => {
          const { params } = matchPath('/app/page/:wiki/:child');
          if (params?.child === newFileId) {
            void mutate(`/drive/v3/files/${res.id}/export`, { html: '' });
            navigate(`/app/page/${wiki.id}/${res.id}`, {
              replace: true,
            });
          }
          return [...files, res];
        }),
        {
          revalidate: false,
          populateCache: true,
          optimisticData: [
            ...files,
            {
              id: newFileId,
              name: file.name,
              mimeType: mimeTypes.shortcut,
              createdTime: new Date().toISOString(),
              parents,
              shortcutDetails: {
                targetId: file.id,
                targetMimeType: file.mimeType,
              },
            },
          ],
        }
      );
      navigate(`/app/page/${wiki.id}/${newFileId}`);
    }
  };

  return (
    <>
      {/* Add Menu */}
      <Menu
        {...bindMenu(addMenu)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transitionDuration={{
          enter: isBun ? 0 : 250,
          exit: 0,
        }}
        onClose={() => {
          addMenu.close();
          setOpenId('');
        }}
        className={cx({
          [darkTheme]: theme === 'dark',
          [lightTheme]: theme === 'light',
        })}
      >
        <MenuItem className={styles.menuItem} onClick={() => openModal('document')}>
          <img
            {...(DEV && {
              referrerPolicy: 'no-referrer',
            })}
            src="https://www.gstatic.com/images/branding/product/1x/docs_48dp.png"
            className={styles.menuImage}
            alt="Document"
            aria-hidden="true"
          />
          Document
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('sheet')}>
          <img
            className={styles.menuImage}
            src="https://www.gstatic.com/images/branding/product/1x/sheets_48dp.png"
            alt="Sheet"
            aria-hidden="true"
          />
          Sheet
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('slide')}>
          <img
            className={styles.menuImage}
            src="https://www.gstatic.com/images/branding/product/1x/slides_48dp.png"
            alt="Slide"
            aria-hidden="true"
          />
          Slide
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('form')}>
          <img
            className={styles.menuImage}
            src="https://www.gstatic.com/images/branding/product/1x/forms_48dp.png"
            alt="Form"
            aria-hidden="true"
          />
          Form
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('folder')}>
          <CreateNewFolderOutlined className={styles.menuIcon} style={{ fontSize: 22 }} />
          Folder
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={() => openModal('link')}>
          <AddLinkOutlined className={styles.menuIcon} style={{ fontSize: 24 }} />
          Link
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={async () => {
            openModal('shortcut');
            await DrivePicker.load();
            DrivePicker.generatePicker({
              type: 'file',
              wiki,
              onAction: (data) => handleAction('shortcut', data),
            });
            DrivePicker.open('file');
          }}
        >
          <AddToDrive style={{ fontSize: 22 }} className={styles.menuIcon} />
          Shortcut
        </MenuItem>
      </Menu>

      {/* More menu */}
      <Menu
        {...bindMenu(moreMenu)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transitionDuration={{
          enter: isBun ? 0 : 250,
          exit: 0,
        }}
        onClose={() => {
          moreMenu.close();
          setOpenId('');
        }}
        className={cx({
          [darkTheme]: theme === 'dark',
          [lightTheme]: theme === 'light',
        })}
      >
        <MenuItem className={styles.menuItem} onClick={() => openModal('rename')}>
          <DriveFileRenameOutline className={styles.menuIcon} style={{ fontSize: 20 }} />
          Rename
        </MenuItem>
        {openFile?.id !== wiki.id && (
          <NestedMenuItem
            className={styles.menuItem}
            parentMenuOpen={moreMenu.isOpen}
            MenuProps={{
              transitionDuration: {
                enter: isBun ? 0 : 250,
                exit: 0,
              },
            }}
            label="Move"
            leftIcon={<SubdirectoryArrowRight className={styles.menuIcon} style={{ fontSize: 20 }} />}
            onClick={async () => {
              openModal('move');
              await DrivePicker.load();
              DrivePicker.generatePicker({
                type: 'folder',
                wiki,
                onAction: (data) => handleAction('move', data),
              });
              DrivePicker.open('folder');
              closeMenus();
            }}
          >
            {fileIndex > 0 && (
              <MenuItem
                onClick={() => {
                  move('up');
                }}
              >
                <ArrowUpward className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move up
              </MenuItem>
            )}
            {fileIndex < siblings.length - 1 && (
              <MenuItem
                onClick={() => {
                  move('down');
                }}
              >
                <ArrowDownward className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move down
              </MenuItem>
            )}
            <MenuItem
              onClick={async () => {
                openModal('move');
                await DrivePicker.load();
                DrivePicker.generatePicker({
                  type: 'folder',
                  wiki,
                  onAction: (data) => handleAction('move', data),
                });
                DrivePicker.open('folder');
                closeMenus();
              }}
            >
              <DriveMove aria-hidden className={styles.menuIcon} style={{ fontSize: 22 }} />
              Move to
            </MenuItem>
            {siblings.length > 1 && <Divider />}
            {fileIndex > 0 && (
              <MenuItem
                onClick={() => {
                  move('top');
                }}
              >
                <VerticalAlignTop className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move to top
              </MenuItem>
            )}
            {fileIndex < siblings.length - 1 && (
              <MenuItem
                onClick={() => {
                  move('bottom');
                }}
              >
                <VerticalAlignBottom className={styles.menuIcon} style={{ fontSize: 20 }} />
                Move to bottom
              </MenuItem>
            )}
            {siblings.length > 1 && openFile?.properties?.index && (
              <MenuItem
                onClick={() => {
                  const nextProperties = {
                    ...openFile.properties,
                    index: null,
                  };
                  void mutateFile({ ...openFile, properties: nextProperties });
                  const nextFiles = files.map((o) => {
                    if (o.id === openId) return { ...o, properties: nextProperties };
                    return o;
                  });
                  void mutateFiles(nextFiles, false);
                  closeMenus();
                }}
              >
                <Replay className={styles.menuIcon} style={{ fontSize: 22 }} />
                Reset position
              </MenuItem>
            )}
          </NestedMenuItem>
        )}
        {openFile?.id !== wiki.id && (
          <MenuItem className={styles.menuItem} onClick={() => openModal('copy')}>
            <DriveCopy className={styles.menuIcon} style={{ fontSize: 20 }} />
            Make a copy
          </MenuItem>
        )}
        <MenuItem
          className={styles.menuItem}
          onClick={() => {
            openModal('share');
          }}
        >
          <PersonAddAlt1Outlined className={styles.menuIcon} style={{ fontSize: 20 }} />
          Share
        </MenuItem>
        {openFile?.id !== wiki.id && (
          <MenuItem className={styles.menuItem} onClick={() => openModal('trash')}>
            <Trash aria-hidden className={styles.menuIcon} style={{ fontSize: 20 }} />
            Trash
          </MenuItem>
        )}
        {openFile?.id === wiki.id && (
          <MenuItem className={styles.menuItem} component={Link} to="/app/settings" onClick={() => closeMenus()}>
            <SettingsOutlined className={styles.menuIcon} style={{ fontSize: 20 }} />
            Settings
          </MenuItem>
        )}
      </Menu>

      {/* Modal */}
      <Modal
        open={Boolean(modal)}
        onClose={closeModal}
        role="dialog"
        className={cx({
          [darkTheme]: theme === 'dark',
          [lightTheme]: theme === 'light',
        })}
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 0,
          },
        }}
      >
        {/* This must be a div so the modal can retain focus */}
        <div>
          {match(modal)
            .with('rename', (modal) => (
              <FileModal
                title="Rename"
                modalType={modal}
                onCancel={closeModal}
                onChange={(e) => setInput(e.target.value)}
                onSubmit={handleSubmit}
                defaultValue={openFile?.name}
              />
            ))
            .with(
              P.when((modal) => pageModalTypes.includes(modal as PageModalType)),
              (modal: PageModalType) => (
                <FileModal
                  title={`New ${modal}`}
                  modalType={modal}
                  onCancel={closeModal}
                  onChange={(e) => setInput(e.target.value)}
                  onSubmit={handleSubmit}
                  defaultValue={`Untitled ${modal}`}
                />
              )
            )
            .with('link', (modal) => (
              <FileModal
                title="New link"
                modalType={modal}
                onCancel={closeModal}
                onChange={(e) => setInput(e.target.value)}
                onChangeURL={(e) => setLinkURL(e.target.value)}
                onSubmit={handleSubmit}
                defaultValue={`Untitled ${modal}`}
              />
            ))
            .with('copy', (modal) => (
              <FileModal
                title="Make a copy"
                modalType={modal}
                onCancel={closeModal}
                onSubmit={handleSubmit}
                description="Are you sure you want to make a copy of this item?"
              />
            ))
            .with('trash', (modal) => (
              <FileModal
                title="Move to trash"
                modalType={modal}
                onCancel={closeModal}
                onSubmit={handleSubmit}
                description={
                  <>
                    Are you sure you want to move {openFile?.name ? <b>{openFile?.name}</b> : 'this item'} to the trash?
                  </>
                }
              />
            ))
            .with('share', () => (
              <ShareModal onCancel={closeModal} file={openFile} wiki={wiki} files={files} wikis={wikis} />
            ))
            .otherwise(() => null)}
        </div>
      </Modal>
    </>
  );
};
