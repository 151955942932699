import * as styles from './PoweredBy.css';

const PoweredBy = () => {
  return (
    <a
      href="https://youneedawiki.com?utm_source=content&utm_medium=trademark&utm_campaign=banner"
      target="_blank"
      className={styles.container}
    >
      <img src="https://youneedawiki.com/img/icon/icon.svg" alt="You Need A Wiki" className={styles.icon} />
      <span>Powered by You Need A Wiki</span>
    </a>
  );
};

export default PoweredBy;
