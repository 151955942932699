import { CircularProgress } from '@mui/material';
import cx from 'classnames';
import { Suspense } from 'react';
import * as styles from '~/components/Page/Page.css';
import Breadcrumbs from '~/elements/Breadcrumbs/Breadcrumbs';
import { CheckboxMacro } from '~/elements/CheckboxMacro';
import { SheetMacro } from '~/elements/SheetSettings/SheetSettings';
import { SkeletonMacro } from '~/elements/SkeletonMacro';
import Title from '~/elements/Title/Title';
import { TreeMacro } from '~/elements/TreeMacro';
import { newFileId } from '~/utils/constants';
import { DriveFile } from '~/utils/DriveAPI';
import { useExport, useFile, useFiles, useInternalNavigation } from '~/utils/hooks';
import { isMarkdownLinkTitle } from '~/utils/isMarkdownLink';
import { parseExport } from '~/utils/parseExport';
import { getId, getIndexPage, getMimeTypeNameFromFile, isADocument, isAFile, isAFolder, macros } from '~/utils/utils';
import LastModified from '../LastModified/LastModified';
import PrevNext from '../PrevNext/PrevNext';
import { StaticTree } from '../Tree/Tree';
import { Document } from './Document';

type Props = {
  folderId: string;
  id?: string | undefined;
};

// Components
export const PageContent = ({ content, file, files }: { content: string; file: DriveFile; files: DriveFile[] }) => {
  return (
    <div role="main" data-clarity-mask="True" className={styles.content}>
      <Document content={content} file={file} files={files} />
    </div>
  );
};

const Page = ({ folderId, id = folderId }: Props) => {
  const { data: allFiles, files } = useFiles(folderId);
  const { data: file, isLoading: isLoadingFile } = useFile(id, files);
  const { data: wiki } = useFile(folderId);
  const indexPage = file ? getIndexPage(file, allFiles) : undefined;
  const { data: exportData, isLoading: isLoadingContent } = useExport(indexPage ?? file);

  const { html } = exportData ?? {};
  const { content } = html ? parseExport({ html, file, files, wiki }) : {};
  const isLoading = (!file && isLoadingFile) || (!html && isLoadingContent) || id === newFileId;
  const pageRef = useInternalNavigation();

  if (!file) return isLoading ? <CircularProgress /> : null;

  const children = isAFolder(file) ? files.filter((o) => o.parents?.[0] === getId(file)) : [];
  const fileType = getMimeTypeNameFromFile(file);
  const markdown = file ? isMarkdownLinkTitle(file.name) : undefined;
  const title = markdown ? markdown.name : file.name;

  return (
    <div>
      <div ref={pageRef} className={cx(styles.page, fileType)}>
        {wiki && <Breadcrumbs files={files} file={file} wiki={wiki} />}
        <div className={styles.titleContainer}>
          <Title className={styles.title}>{title}</Title>
        </div>
        {isAFile(file) && <LastModified file={file} content={content} />}
        {isLoading && <CircularProgress />}
        {content && <PageContent content={content} file={file} files={allFiles} />}
        {wiki && children.length > 0 && !indexPage && (
          <div className={styles.content}>
            <StaticTree wiki={wiki} files={children} />
          </div>
        )}
        {wiki && <PrevNext file={file} files={files} wiki={wiki} />}

        {/* Macros */}
        {content?.includes('data-react="SkeletonLoader') && (
          <SkeletonMacro content={content} query={`[data-react="SkeletonLoader"]`} />
        )}
        {content && isADocument(file) && macros.test.checkbox.test(content) && (
          <CheckboxMacro content={content} query={`[data-react="Checkbox"]`} id={file.id} />
        )}
        {content && wiki && indexPage && macros.test.files.test(content) && (
          <Suspense>
            <TreeMacro wiki={wiki} files={children} content={content} query={`[data-react="Tree"]`} />
          </Suspense>
        )}
        {file && content?.includes('data-react="SheetSettings"') && (
          <SheetMacro file={file} query={`[data-react="SheetSettings"]`} />
        )}
      </div>
    </div>
  );
};

export const PageSpec = ({ html }: { html: string }) => {
  const fileType = 'document';
  const { content } = html ? parseExport({ html, file: undefined, files: [], wiki: undefined }) : { content: '' };
  return (
    <div
      role="main"
      className={cx(styles.page, fileType)}
      style={{
        marginTop: '2.8rem',
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default Page;
