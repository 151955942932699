import cx from 'classnames';
import { useMemo } from 'react';
import * as pageStyles from '~/components/Page/Page.css';
import { DriveFile } from '~/utils/DriveAPI';
import { getIndexPage, getMimeTypeNameFromFile, macros } from '~/utils/utils';

export const Document = ({
  content,
  file,
  files,
}: {
  content: string;
  file: DriveFile;
  files: DriveFile[];
}) => {
  const fileType = getMimeTypeNameFromFile(file);
  const indexPage = getIndexPage(file, files);

  const value = (
    indexPage
      ? content.replace(macros.global.files, /*html*/ `<div data-testid="tree" data-react="Tree"></div>`)
      : content
  )
    .replace(
      macros.global.checkedbox,
      /*html*/ `<span data-react="Checkbox" data-props='${JSON.stringify({ checked: true })}'></span>`
    )
    .replace(macros.global.checkbox, /*html*/ `<span data-react="Checkbox"></span>`);

  return (
    <>
      <div
        className={cx(pageStyles.page, fileType)}
        // https://github.com/facebook/react/issues/31660#issuecomment-2585166304
        dangerouslySetInnerHTML={useMemo(() => ({ __html: value }), [value])}
      />
    </>
  );
};
