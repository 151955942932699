import { Button } from '@mui/base/Button';
import { Check, ContentCopy } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import * as buttonStyles from '~/elements/Buttons/Button.css';
import { tooltipSlotProps } from '../Tooltip';

const CopyToClipboard = ({
  text,
  copyStyle,
  checkStyle,
  containerStyle,
}: {
  text: string | undefined;
  copyStyle?: React.CSSProperties;
  checkStyle?: React.CSSProperties;
  containerStyle: React.CSSProperties;
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (!text || copied) return;
    void navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Tooltip arrow open={copied} title="Copied!" slotProps={tooltipSlotProps}>
      {/* Required for tooltip forwardRef */}
      <Button
        type="button"
        className={buttonStyles.buttonCircular}
        style={{
          display: 'inline-flex',
          ...containerStyle,
          width: 34,
          height: 34,
        }}
        onClick={handleCopy}
      >
        {copied ? (
          <Check
            style={{
              color: '#4CAF50',
              fontSize: 19,
              ...checkStyle,
            }}
          />
        ) : (
          <ContentCopy
            style={{
              fontSize: '1.1rem',
              cursor: 'pointer',
              color: '#7d7d7d',
              ...copyStyle,
            }}
          />
        )}
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboard;
