import { preload, type Cache } from 'swr';
import { exportFile, type DriveFile } from './DriveAPI';
import { assertType, getId, getMimeType, isExportable } from './utils';

export const prefetch = (file: DriveFile, cache: Cache) => {
  if (!isExportable(file)) return;
  // The cache is a Map<string, string> during the user session so we can use map methods
  assertType<Map<string, string>>(cache);
  const isCached = Boolean(cache.has(`/drive/v3/files/${getId(file)}/export`));
  if (isCached) return;
  const id = file ? getId(file) : undefined;
  const mimeType = getMimeType(file);
  if (!id) throw new Error('No id provided');
  if (!mimeType) throw new Error('No mimeType provided');
  void preload(`/drive/v3/files/${id}/export`, () => exportFile({ id, mimeType }));
};
