import { Fade, type TooltipProps } from '@mui/material';

export const tooltipSlotProps = {
  arrow: {
    sx: {
      color: '#3e3e3e',
    },
  },
  popper: {
    sx: {
      '& .MuiTooltip-tooltip': {
        padding: '4px 8px 5px 8px',
      },
    },
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -6],
        },
      },
    ],
  },
  tooltip: {
    sx: {
      fontFamily: 'var(--font)',
      fontSize: 12.5,
      backgroundColor: '#3e3e3e',
    },
  },
} satisfies TooltipProps['slotProps'];

export const tooltipSlots = {
  transition: Fade,
} satisfies TooltipProps['slots'];
