import { use, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { StaticTree } from '~/components/Tree/Tree';
import { type DriveFile } from '~/utils/DriveAPI';

const getNodes = async (query: string) => Array.from(document.querySelectorAll(query));

export const TreeMacro = ({
  wiki,
  files,
  content,
  query,
}: {
  wiki: DriveFile;
  files: DriveFile[];
  content: string;
  query: string;
}) => {
  // Attach to the nodes after dangerouslySetInnerHTML has run
  // biome-ignore lint/correctness/useExhaustiveDependencies: not needed
  const p = useMemo(() => getNodes(query), [content]);
  const nodes = use<Element[]>(p);
  return nodes.map((node) => createPortal(<StaticTree wiki={wiki} files={files} />, node));
};
