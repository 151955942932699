import { OpenInNew } from '@mui/icons-material';
import { useRef } from 'react';
import { DriveFile } from '~/utils/DriveAPI';
import { useKeyPress } from '~/utils/hooks';
import { isAFile } from '~/utils/utils';
import * as styles from './EditButton.css';

const EditButton = ({
  file,
  onClick,
  isIndexPage,
}: {
  isIndexPage?: boolean;
  file: DriveFile | undefined;
  onClick?: () => void;
}) => {
  // Keyboard support for edit buttons
  const editButtonRef = useRef<HTMLAnchorElement>(null);

  // Edit button
  useKeyPress('e', () => {
    if (editButtonRef.current) {
      editButtonRef.current.click();
    }
  });

  if (!file || !file.webViewLink) return null;
  // render when is an index page or file
  if (!isIndexPage && !isAFile(file)) return null;

  return (
    <a
      ref={editButtonRef}
      target="_blank"
      href={file.webViewLink}
      data-testid="button-edit"
      className={styles.buttonContainer}
      aria-label="Edit"
      onClick={onClick}
    >
      <span>Edit</span>
      <OpenInNew
        style={{
          fontSize: 16,
          color: 'rgba(0, 0, 0, 0.5)',
          position: 'relative',
          top: 1,
        }}
      />
    </a>
  );
};

export default EditButton;
