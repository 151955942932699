import { type DriveFile } from '~/utils/DriveAPI';
import { sort } from '~/utils/sort';
import { omit } from '~/utils/utils';

const getNextIndex = (currentIndex: number, direction: 'up' | 'down' | 'top' | 'bottom', length: number) => {
  if (direction === 'bottom') return Infinity;
  if (direction === 'down') {
    if (currentIndex + 1 > length - 1) return Infinity;
    if (currentIndex + 1 === length - 1) return Infinity;
    return currentIndex + 1;
  }
  if (direction === 'up') {
    return currentIndex - 1;
  }
  return 0;
};

const getSiblingIndex = (
  currentFileIndex: number,
  currentSiblingIndex: number,
  propertyIndex: number,
  direction: 'up' | 'down' | 'top' | 'bottom',
  defaultSiblingIndex: number,
  length: number
) => {
  // Debugging
  // if (direction === 'down')
  //   console.log({ direction, currentFileIndex, currentSiblingIndex, defaultSiblingIndex, length });
  if (direction === 'down') {
    if (defaultSiblingIndex === currentSiblingIndex) return undefined;
    return currentSiblingIndex - 1;
  }
  if (direction === 'top' && currentSiblingIndex === defaultSiblingIndex) return undefined;
  if (direction === 'bottom') {
    if (currentSiblingIndex === defaultSiblingIndex) return undefined;
    if (length - 1 === defaultSiblingIndex) return undefined;
    return length - 1;
  }
  if (propertyIndex === Infinity) return undefined;

  // If it's up or down return the currentFileIndex to do the switch
  return currentFileIndex;
};

export type Direction = 'up' | 'down' | 'top' | 'bottom';
const getMovedFiles = (
  files: DriveFile[],
  fileToMove: DriveFile,
  direction: 'up' | 'down' | 'top' | 'bottom'
): {
  nextFiles: DriveFile[];
  nextFile: DriveFile;
  nextSiblingFile: DriveFile | undefined;
} => {
  const children = files.filter((o) => o.parents?.[0] === fileToMove.parents?.[0]);
  const sortedChildren = sort(children);
  // console.log(sortedChildren);
  const propertyIndex = Number(fileToMove.properties?.index);
  const currentFileIndex = fileToMove.properties?.index
    ? propertyIndex === Infinity
      ? children.length - 1
      : propertyIndex
    : sortedChildren.findIndex((o) => o.id === fileToMove.id);

  const directionMap = {
    up: -1,
    down: 1,
    top: -currentFileIndex,
    bottom: children.length - 1,
  };
  const moveValue = directionMap[direction];
  const nextIndex = getNextIndex(currentFileIndex, direction, children.length);
  const nextFile = {
    ...fileToMove,
    properties: {
      ...fileToMove.properties,
      index: typeof nextIndex === 'number' ? `${nextIndex}` : nextIndex,
    },
  };

  const currentSiblingIndex =
    currentFileIndex + moveValue < children.length ? currentFileIndex + moveValue : children.length - 1;
  const siblingFile = sortedChildren[currentSiblingIndex];
  const defaultSiblingIndex = sort(
    children.map((o) => {
      if (o.id === siblingFile?.id) {
        return {
          ...o,
          properties: o.properties ? omit(o.properties, 'index') : {},
        };
      }
      return o;
    })
  ).findIndex((o) => o.id === siblingFile?.id);

  const nextSiblingIndex = getSiblingIndex(
    currentFileIndex,
    currentSiblingIndex,
    propertyIndex,
    direction,
    defaultSiblingIndex,
    children.length
  );

  if (currentFileIndex === 0 && direction === 'up') {
    return {
      nextFiles: files,
      nextFile: fileToMove,
      nextSiblingFile: undefined,
    };
  }

  if (!siblingFile) {
    throw new Error('siblingFile is undefined');
  }

  const nextSiblingFile = {
    ...siblingFile,
    properties: {
      ...siblingFile.properties,
      index: typeof nextSiblingIndex === 'number' ? `${nextSiblingIndex}` : nextSiblingIndex,
    },
  } as DriveFile;

  // Update all files
  const nextFiles = files.map((o) => {
    if (o.id === fileToMove.id) return nextFile;
    if (o.id === nextSiblingFile?.id) return nextSiblingFile;
    return o;
  });

  return {
    nextFiles,
    nextFile,
    nextSiblingFile,
  };
};
export default getMovedFiles;
