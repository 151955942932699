import { useEffect, useReducer, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { serialize, SWRResponse } from 'swr/_internal';
import useSWRInfinite from 'swr/infinite';
import { useLocation } from 'wouter';
import { create } from 'zustand';
import type { DriveFile, DriveFileWithExport, ExportResult } from './DriveAPI';
import * as DriveAPI from './DriveAPI';
import { copyFileId, defaultScope, DEV, expiredToken, isBun, mimeTypes, newFileId } from './constants';
import { generateQuery } from './generateQuery';
import gup from './gup';
import { isMarkdownLinkTitle } from './isMarkdownLink';
import { log } from './logger';
import { recursiveFetch } from './middleware/recursiveFetch';
import { setToken } from './middleware/setToken';
import { throttle } from './middleware/throttle';
import { sortByNameFn } from './sort';
import type { Profile } from './types';
import {
  getId,
  getMimeType,
  getSharedDriveId,
  getTextFromFile,
  isADocument,
  isAFolder,
  isAShortcut,
  isExportable,
  isInputEvent,
  sortArrByName,
  uniqueBy,
} from './utils';

/**
 * Avoids unnecessary re-renders due to using spread operator on swr object
 * https://github.com/vercel/swr/pull/890
 */
const assign = <T extends SWRResponse, O extends Record<string, unknown>>(swr: T, obj: O): T & O => {
  const descriptors: Partial<Record<keyof O, PropertyDescriptor>> = {};

  for (const [key, value] of Object.entries(obj)) {
    descriptors[key] = { value, enumerable: true };
  }

  return Object.defineProperties(swr, descriptors as Record<keyof O, PropertyDescriptor>) as T & O;
};

// The modern token refresh is handled by the server
export let useAuth = (): {
  anonymous: boolean | undefined;
  authorized: boolean;
  loaded: boolean;
  user: Profile | undefined;
} => {
  const { data: user, isLoading } = useSWR<Profile | null, unknown>(
    '/api/user/me',
    async (url: string) => {
      log.info('GET: /user/me');
      const response = await fetch(url, {
        priority: 'high',
      });
      if (!response.ok) return null;
      const user = await response.json<Profile | undefined>();
      log.info('user', user);
      if (!user) return null;
      user.tokens.expiresAt = Date.now() + user.tokens.expires;
      return user;
    },
    {
      use: [setToken],
      refreshInterval: (data) => (data && 'tokens' in data ? data.tokens.expiresAt - Date.now() : 0),
    }
  );

  if (user && 'tokens' in user) {
    const expired = Date.now() > (user.tokens.expiresAt ?? 0);
    return {
      loaded: !expired && !isLoading,
      anonymous: false,
      authorized: !expired,
      user,
    };
  }

  return {
    loaded: user === null && !isLoading,
    anonymous: true,
    authorized: false,
    user: undefined,
  };
};

// The legacy token refresh is handled by the gapi client
if (import.meta.env.VITE_LEGACY) {
  useAuth = (): { anonymous: boolean; authorized: boolean; loaded: boolean; user: Profile | undefined } => {
    const unauthorized = { anonymous: Boolean(gup('p')), loaded: true, authorized: false, user: undefined };
    if (typeof gapi === 'undefined' || !gapi.auth2) return { ...unauthorized, loaded: false };
    const authInstance = gapi.auth2.getAuthInstance();
    if (!authInstance) return unauthorized;
    const currentUser = gapi.auth2.getAuthInstance().currentUser.get();
    const profile = currentUser.getBasicProfile();
    if (!profile) return unauthorized;
    const auth = currentUser.getAuthResponse();

    const user = {
      id: profile.getId(),
      name: profile.getName(),
      email: profile.getEmail(),
      image: profile.getImageUrl(),
      tokens: {
        accessToken: auth.access_token,
        expires: auth.expires_in,
        expiresAt: auth.expires_at,
        scope: auth.scope,
      },
    } as const;

    return {
      loaded: true,
      anonymous: false,
      authorized: Boolean(user.tokens.accessToken),
      user,
    };
  };
}

export const useFolder = () => {
  const query = `'root' in parents and mimeType = 'application/vnd.google-apps.folder' and name = 'YNAW'`;
  return useSWR<DriveFile | undefined, unknown>(`/drive/v3/files?q=${query}`, async () => {
    if (!query) return Promise.reject(new Error('No query provided'));
    const folders = await DriveAPI.list({ query, fields: 'files(id, name)' });
    return folders.at(0);
  });
};

export const useWikis = (id: string | undefined) => {
  const query = `'${id}' in parents and (mimeType = '${mimeTypes.folder}' or mimeType = '${mimeTypes.shortcut}' and shortcutDetails.targetMimeType = '${mimeTypes.folder}')`;
  return useSWR<DriveFile[] | undefined, unknown>(id ? `/drive/v3/files?q=${query}` : null, async () => {
    if (!query) return Promise.reject(new Error('No query provided'));
    const files = await DriveAPI.list({ query });
    const folders = files.filter((o) => o.mimeType === mimeTypes.folder);
    const shortcuts = files.filter((o) => isAShortcut(o));
    const requests = shortcuts.map((o) => DriveAPI.get({ id: o.shortcutDetails.targetId }));
    const responses = await Promise.allSettled(requests);
    const wikis = responses.map((o) => ('value' in o ? o.value : undefined)).filter((o): o is DriveFile => !!o);
    return sortArrByName(folders.concat(wikis));
  });
};

/**
 * Recursively fetches all files in the wiki
 * Updates the swr cache once completed
 */
export const useFiles = (id: string | undefined | null) => {
  const [streamed, setStreamed] = useStreamed();
  const { email } = useAuth().user ?? {};
  const idRef = useRef<string | undefined | null>(id);
  const swr = useSWR(
    id
      ? {
          // Use /api instead of /drive as this returns all files
          key: `/api/files/${id}`,
          id,
          email,
        }
      : null,
    (data: DriveFile[]): DriveFile[] => {
      if (!id) throw new Error('No id provided');
      if (id !== idRef.current) throw new Error('id changed');
      setStreamed((prev) => [...prev, ...data]);
      return [];
    },
    {
      use: [recursiveFetch],
    }
  );

  useEffect(() => {
    if (id !== idRef.current) {
      idRef.current = id;
      setStreamed(() => []);
    }
  }, [id, setStreamed]);

  const data = swr.data?.length ? swr.data : streamed;
  const files = data.filter((file) => file.name !== 'wiki.logo' && file.name !== 'wiki.page');
  return assign(swr, { data, files });
};

export const useFile = (id: string, files?: DriveFile[]) => {
  const isNewFile = id === newFileId || id === copyFileId;
  const swr = useSWR<DriveFile, unknown>(isNewFile ? null : `/drive/v3/files/${id}`, () => DriveAPI.get({ id }), {
    use: [throttle],
  });
  if (!files) return swr;
  const file = files?.find((o) => o.id === id);
  const { data } = swr;
  if (data && !data.parents && file?.parents) {
    return assign(swr, { data: { ...data, parents: file.parents } });
  }
  return assign(swr, { data: data ?? file });
};

export const useExport = (file: DriveFile | undefined) => {
  const id = file && isExportable(file) ? getId(file) : null;
  const mimeType = getMimeType(file);
  const swr = useSWR(
    id ? `/drive/v3/files/${id}/export` : null,
    async () => {
      if (!id) throw new Error('No id provided');
      if (!mimeType) throw new Error('No mimeType provided');
      return DriveAPI.exportFile({ id, mimeType });
    },
    {
      use: [throttle],
    }
  );
  return swr;
};

/**
 * Gets exports for all files provided
 */
export const useExports = (
  files: DriveFile[] | undefined
): { data: DriveAPI.DriveFileWithExport[]; isLoading: boolean } => {
  const { data: results, isLoading } = useSWRInfinite(
    (pageIndex) => {
      if (!files?.length) return null;
      const file = files[pageIndex];
      if (!file) return null;
      return `/drive/v3/files/${file.id}/export`;
    },
    async (args) => {
      const id = args.split('/').at(-2);
      if (!id) throw new Error('No id provided');
      const file = files?.find((o) => o.id === id);
      const mimeType = getMimeType(file);
      if (!mimeType) throw new Error('No mimeType provided');
      return DriveAPI.exportFile({ id, mimeType });
    },
    {
      initialSize: files?.length ?? 0,
      revalidateAll: true,
      parallel: true,
    }
  );
  if (!results) return { data: [], isLoading };

  const data =
    files?.map((file, i) => {
      const data = results[i];
      return {
        ...file,
        data: data ?? {
          html: '',
        },
      };
    }) ?? [];

  return {
    data,
    isLoading,
  };
};

export const useCachedExports = (files: DriveFile[]): DriveFileWithExport[] => {
  const { cache } = useSWRConfig();

  const data = files
    .filter(isExportable)
    .map((file) => {
      const id = getId(file);
      if (!id) {
        TrackJS.console.log(file);
        TrackJS.track('useCachedExports - file does not contain id');
        return {
          ...file,
          data: {
            html: '',
          },
        };
      }
      const path = serialize(`/drive/v3/files/${id}/export`)?.[0];
      const data = cache.get(path)?.data as ExportResult | undefined;

      return {
        ...file,
        data,
      };
    })
    // If the export returns an error we cache an empty string to prevent the request from being made again
    // Which is why we're checking for a result or empty string here
    .filter((o) => o.data?.html || o.data?.html === '') as DriveFileWithExport[];

  return data;
};

/**
 * Immediately sets a boolean value to true but debounces setting it to false by a specified delay.
 */
export function useLeadingDebounceTruthy(value: boolean, delay = 100): boolean {
  const [debouncedValue, setDebouncedValue] = useState<boolean>(value);

  useEffect(() => {
    if (value) {
      setDebouncedValue(true);
    } else {
      const handler = setTimeout(() => {
        setDebouncedValue(false);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
    return;
  }, [value, delay]);

  if (isBun) return value;
  return debouncedValue;
}

export function useLeadingDebounce<T>(value: T, delay = 100): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timerRef = useRef<Timer>(undefined);
  const firstValue = useRef<T>(value);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (value !== debouncedValue) {
      if (value === firstValue.current) {
        setDebouncedValue(value);
        return;
      }

      timerRef.current = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
    }

    return () => clearTimeout(timerRef.current);
  }, [value, delay, debouncedValue]);

  return debouncedValue;
}

/**
 * Returns search results based on query
 * @param input - Search query
 * @param wiki - The wiki folder
 * @param files - All files in the wiki
 * @param exportLimit - Limit the number of export requests
 */
export const useSearch = (
  input: string,
  wiki: DriveFile,
  files: DriveFile[]
): { data: DriveFile[]; isLoading: boolean } => {
  const { user } = useAuth();
  const lowerInput = input.toLowerCase();
  const folders = [wiki].concat(files.filter(isAFolder));
  const query =
    input.length > 0
      ? generateQuery({ files: folders, wiki, email: user?.email, user, isTextSearch: true })
      : undefined;
  const prefix = '/drive/v3/files?q=';
  const {
    data: results,
    isLoading: isLoadingResults,
    isValidating: isValidatingResults,
  } = useSWRInfinite(
    (pageIndex) => {
      if (!query) return null;
      const q = typeof query === 'string' ? query : query[pageIndex];
      if (!q) return null;
      return `${prefix}fullText contains '${lowerInput}' and ${typeof query === 'string' ? `(${q})` : `${q}`}`;
    },
    async (args) => {
      const query = args.replace(prefix, '');
      const driveId = getSharedDriveId(folders);
      const data = (await DriveAPI.search({
        q: query,
        fields: 'files(id,name)',
        ...(driveId && { driveId }),
      }).catch(() => [])) as { id: string }[];
      return data ?? [];
    },
    {
      revalidateAll: true,
      initialSize: query ? (typeof query === 'string' ? 1 : query.length) : 0,
      parallel: true,
    }
  );

  const ids = results?.flat().map((o) => o.id) ?? [];
  const searchResults = files.filter((o) => ids.includes(o.id));
  // only export a file if it's in the search results
  // run a check against cached files as a bonus (may be disabled in the future)
  const { data: _searchFiles, isLoading: isLoadingExports } = useExports(searchResults);
  // some search matches are within iframe elements and we filter those out
  const searchFiles = _searchFiles
    .map((o) => ({ ...o, content: getTextFromFile(o) }))
    .filter((o) => o.content.includes(lowerInput));
  const cachedFilesWithHTML = useCachedExports(files)
    .map((file) => ({
      ...file,
      content: getTextFromFile(file),
    }))
    .filter((file) => file.content.includes(lowerInput));
  const nameFiles = files.filter((file) => file.name.toLowerCase().includes(lowerInput));
  type SearchEntry = DriveFile | (DriveFile & { content: string }) | DriveAPI.DriveFileWithExport;

  const unsortedData =
    input.length === 0
      ? []
      : ([...cachedFilesWithHTML, ...searchFiles, ...nameFiles].map((o: SearchEntry) => {
          const markdown = isADocument(o) ? isMarkdownLinkTitle(o.name) : undefined;
          return {
            ...o,
            nameLower: markdown ? markdown.name.toLowerCase() : o.name.toLowerCase(),
            content: 'content' in o ? o.content : 'data' in o ? getTextFromFile(o) : '',
          };
        }) as (DriveFile & { content: string; nameLower: string })[]);
  const data = uniqueBy(unsortedData, 'id')
    .filter((o, i, arr) => {
      // We can safely filter markdown links with the same name as they will generate the same result
      const isMarkdown = isADocument(o) && isMarkdownLinkTitle(o.name);
      if (!isMarkdown) return true;
      const duplicateNameIndex = arr.findIndex((f) => f.name === o.name && f.id !== o.id);
      const hasDuplicateName = duplicateNameIndex !== -1;
      const isDuplicateName = hasDuplicateName && i > duplicateNameIndex;
      return !isDuplicateName;
    })
    .sort(sortByNameFn)
    .sort((a, b) => {
      if (a.nameLower.startsWith(lowerInput) && !b.nameLower.startsWith(lowerInput)) return -1;
      if (!a.nameLower.startsWith(lowerInput) && b.nameLower.startsWith(lowerInput)) return 1;
      if (a.nameLower.includes(lowerInput) && !b.nameLower.includes(lowerInput)) return -1;
      if (a.nameLower.includes(lowerInput) && a.content.includes(lowerInput) && !b.content.includes(lowerInput)) {
        return -1;
      }
      if (a.content.includes(lowerInput) && !b.nameLower.includes(lowerInput) && !b.content.includes(lowerInput))
        return -1;
      return 0;
    });

  const isLoadingValue = useLeadingDebounceTruthy(isLoadingResults || isLoadingExports || isValidatingResults, 200);
  const isLoading = input.length === 0 ? false : isLoadingValue;
  return {
    data,
    isLoading,
  };
};

// https://github.com/jacobbuck/react-use-keypress/blob/main/src/index.js
export const useKeyPress = (
  keys: KeyboardEvent['code'] | KeyboardEvent['code'][],
  callback: ((e: KeyboardEvent) => void) | undefined,
  deps?: unknown[]
) => {
  const eventListenerRef = useRef<(e: KeyboardEvent) => void>(undefined);
  // biome-ignore lint/correctness/useExhaustiveDependencies: intended
  useEffect(
    () => {
      if (!callback) return;
      eventListenerRef.current = (event: KeyboardEvent) => {
        if (Array.isArray(keys) ? keys.includes(event.key) : keys === event.key) {
          callback?.(event);
        }
      };
    },
    deps ? deps : [keys, callback]
  );

  useEffect(() => {
    if (!eventListenerRef.current) return;
    const eventListener = (event: KeyboardEvent) => {
      if (isInputEvent(event)) return;
      eventListenerRef.current?.(event);
    };
    window.addEventListener('keydown', eventListener);
    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  }, []);
};

export const useIsVisible = <T extends React.RefObject<HTMLElement | null>>(
  ref: T,
  cb: () => void,
  deps: unknown[] = []
): void => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: intended
  useEffect(() => {
    const target = ref.current;
    if (!window.IntersectionObserver) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          cb();
          if (target) observer.unobserve(target);
        }
      });
    });
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, deps);
};

/**
 * This is a hook to allow testing invalidating the token while the user is browsing
 */
export const useSimulateExpiredToken = (delay = 3000) => {
  const { cache } = useSWRConfig();
  // Simulate expired token
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const timerRef = useRef<Timer>(undefined);

  useEffect(() => {
    log.info('simulating expired token in', delay / 1000, 'seconds');
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      log.info('simulating expired token');
      cache.set('/api/user/me', {
        data: {
          tokens: {
            accessToken: 'expired',
            expires: 0,
            expiresAt: 0,
          },
        },
      });
      gapi.client.setToken({ access_token: 'expired', expires_in: 0, scope: defaultScope });
      forceUpdate();
    }, delay);
  }, [cache, delay]);
};

if (DEV && expiredToken) {
  gapi.client.setToken({
    access_token: 'expired',
    expires_in: 0,
    scope: defaultScope,
  });
}

export const useBeforeUnload = (callback: () => void) => {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => {
      callbackRef.current();
    };
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, []);
};

export const useLast = <T>(value: T, deps: unknown[] = []): T | undefined => {
  const [lastValue, setLastValue] = useState<T | undefined>(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: intended
  useEffect(() => {
    setLastValue(value);
  }, deps);

  return lastValue;
};

const shouldSkipNavigation = (url: URL): boolean => {
  if (!url.hostname) return true;
  const { pathname } = window.location;
  if (pathname === url.pathname) return true;

  const isInternal =
    url.hostname === window.location.hostname || url.hostname === 'localhost' || url.hostname === 'youneedawiki.com';
  if (!isInternal || !url.pathname.startsWith('/app')) return true;
  return false;
};

/**
 * Converts link clicks to internal navigation
 */
export const useInternalNavigation = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const navigate = useLocation()[1];
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const { target } = e;
      if (!target || !(target instanceof HTMLAnchorElement) || !pageRef?.current?.contains(target) || !target.href) {
        return;
      }
      const { href } = target;
      const url = new URL(href);
      if (shouldSkipNavigation(url)) return;
      e.preventDefault();
      const path = href.replace(url.origin, '');
      navigate(path);
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [navigate]);
  return pageRef;
};

// Theming
type Theme = 'dark' | 'light';
type ThemeState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

type Store = ThemeState & {
  openId: string;
  setOpenId: (id: string) => void;
  streamed: DriveFile[];
  setStreamed: (fn: (prev: DriveFile[]) => DriveFile[]) => void;
};

const theme = isBun
  ? 'light'
  : ((localStorage.getItem('theme') as Theme | null) ??
    (matchMedia('(prefers-color-scheme:dark)').matches ? 'dark' : 'light'));

export const useStore = create<Store>()((set) => ({
  openId: '',
  setOpenId: (openId) => set({ openId }),
  theme,
  streamed: [],
  setStreamed: (fn) => set(({ streamed }) => ({ streamed: fn(streamed) })),
  setTheme: (theme) => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
    return set({ theme });
  },
}));

export const useOpenId = () => {
  const openId = useStore((state) => state.openId);
  const setOpenId = useStore((state) => state.setOpenId);
  return [openId, setOpenId] as const;
};

export const useTheme = () => {
  const theme = useStore((state) => state.theme);
  const setTheme = useStore((state) => state.setTheme);
  return [theme, setTheme] as const;
};

const useStreamed = () => {
  const streamed = useStore((state) => state.streamed);
  const setStreamed = useStore((state) => state.setStreamed);
  return [streamed, setStreamed] as const;
};
