import { type Middleware, useSWRConfig } from 'swr/_internal';
import { apiKey } from '../constants';
import { type Profile } from '../types';

/**
 * Throttles cached requests to avoid unnecessary fetches
 */
export const setToken: Middleware = (useSWRNext) => (key, fetcher, config) => {
  const cache = useSWRConfig().cache;
  let loaded = false;

  const extendedFetcher = async (...args: string[]) => {
    const id = args[0];
    if (!id) throw new Error('No id provided');
    if (!fetcher) return undefined as typeof result;

    const value = cache.get(id) as { data: Profile } | undefined;
    const cachedUser = value?.data;
    const expired = Date.now() > (cachedUser?.tokens.expiresAt ?? 0);
    const request = !cachedUser || expired ? fetcher(...args) : (cachedUser as ReturnType<typeof fetcher>);

    if (!loaded) {
      await gapi_loaded.promise;
      gapi.client.setApiKey(apiKey);
      loaded = true;
    }

    const result = await request;
    const user = result as Profile | undefined;
    if (user && 'tokens' in user) {
      gapi.client.setToken({
        access_token: user.tokens.accessToken,
        expires_in: user.tokens.expires,
        scope: user.tokens.scope,
      });
    }

    return result;
  };

  return useSWRNext(key, extendedFetcher, config);
};
