import { CircularProgress } from '@mui/material';
import * as appStyles from '~/app.css';
import { colors } from '~/theme.css';
import { useFile, useFiles, useFolder, useWikis } from '~/utils/hooks';
import Tree from '../Tree/Tree';
import WikiSelector from '../WikiSelector/WikiSelector';

type Props = {
  folderId: string;
  id?: string | undefined;
};

const Sidebar = ({ folderId, id = folderId }: Props) => {
  const { data: appFolder } = useFolder();
  const { data: wikis = [] } = useWikis(appFolder?.id);
  const { data: wiki, isLoading: isWikiLoading } = useFile(folderId);
  const { files } = useFiles(folderId);
  const isLoading = !wiki && isWikiLoading;

  if (isLoading) {
    return <CircularProgress style={{ color: colors.spinner.default }} thickness={4} size={34} />;
  }
  if (!wiki) return null;
  const { color } = wiki?.properties ?? {};

  return (
    <nav
      className={appStyles.sidebar}
      style={{
        backgroundColor: color ?? '#638AF5',
      }}
    >
      <WikiSelector wikis={wikis} wiki={wiki} />
      <Tree files={files} wiki={wiki} wikis={wikis} id={id} closedByDefault showHome />
    </nav>
  );
};

export default Sidebar;
