import { Folder, OpenInNew } from '@mui/icons-material';
import cx from 'classnames';
import { Document, Shortcut } from '~/elements/icons';
import { DEV, isBun, LEGACY } from '~/utils/constants';
import { type DriveFile } from '~/utils/DriveAPI';
import { type ExternaLink, isMarkdownLinkTitle } from '~/utils/isMarkdownLink';
import { colorIsDark, getMimeTypeNameFromFile, isADocument, isAFolder, isAShortcut, omit } from '~/utils/utils';
import * as styles from './Icon.css';

// See: youneedawiki/public/img
const supportedIcons = ['document', 'spreadsheet', 'presentation', 'form'];

const Icon = ({
  file,
  className,
  color,
  bgColor = '#638AF5',
  hasChildren,
  externalLink,
  ariaHidden = isBun,
  sidebar = false,
}: {
  file: DriveFile;
  className?: string | undefined;
  color?: string | undefined;
  bgColor?: string | undefined;
  hasChildren?: boolean;
  externalLink?: ExternaLink | undefined;
  iconLinkOnly?: boolean;
  ariaHidden?: boolean | undefined;
  sidebar?: boolean;
}) => {
  if (isAShortcut(file)) {
    if (hasChildren) return null;
    const nextFile = {
      ...omit(file, 'shortcutDetails'),
      mimeType: file.shortcutDetails?.targetMimeType,
      ...(file.iconLink && {
        iconLink: file.iconLink?.replace(/\/16\/type.+/, `/32/type/${file.shortcutDetails.targetMimeType}`),
      }),
    } as DriveFile;

    return (
      <div className={cx([styles.icon])}>
        <Icon
          className={className}
          file={nextFile}
          color={color}
          bgColor={bgColor}
          ariaHidden={ariaHidden}
          sidebar={sidebar}
        />
        <Shortcut aria-hidden={ariaHidden} className={styles.shortcut} viewBox="0 0 257 257" />
      </div>
    );
  }

  if (isAFolder(file)) {
    return hasChildren ? null : (
      <div className={cx([styles.folderContainer, className])}>
        <Folder
          aria-hidden={ariaHidden}
          className={cx(styles.folder)}
          style={{
            color: sidebar ? (color ?? '#fff') : undefined,
            // opacity: sidebar ? 0.8 : undefined,
            // opacity: 0.8,
          }}
        />
      </div>
    );
  }

  const isDocument = isADocument(file);
  const isAnExternalLink = isDocument ? (externalLink ?? isMarkdownLinkTitle(file.name)) : undefined;
  if (isAnExternalLink) {
    return (
      <div className={styles.icon}>
        <div
          style={{
            marginLeft: -2,
          }}
        >
          <OpenInNew
            aria-hidden={ariaHidden}
            style={{
              color: sidebar ? (color ?? '#fff') : undefined,
            }}
            className={cx(styles.openInNew, className)}
          />
        </div>
      </div>
    );
  }

  if (isDocument && sidebar && colorIsDark(bgColor)) {
    return (
      <div className={styles.icon}>
        <Document
          aria-hidden={ariaHidden}
          className={cx(styles.document, className)}
          style={{ color: sidebar ? (color ?? '#fff') : undefined }}
          viewBox="0 0 257 257"
        />
      </div>
    );
  }

  const mimeTypeName = getMimeTypeNameFromFile(file);
  const src =
    !LEGACY && mimeTypeName && supportedIcons.includes(mimeTypeName)
      ? `/img/${mimeTypeName}.png`
      : file.iconLink
        ? file.iconLink.replace(/\/16\/type/, '/32/type')
        : null;

  return src ? (
    <img
      {...(DEV && {
        referrerPolicy: 'no-referrer',
      })}
      alt={getMimeTypeNameFromFile(file) ?? 'icon'}
      src={src}
      className={cx(styles.iconImage, className)}
    />
  ) : null;
};

export default Icon;
