import { mimeTypes } from './constants';
import { type DriveFile } from './DriveAPI';

export type ExternaLink = {
  name: string;
  url: string;
};

// In order to avoid a circular dependency, duplicate the isADocument function in this file
const isADocument = (file: DriveFile | undefined) =>
  file
    ? file.mimeType === mimeTypes.document ||
      ('shortcutDetails' in file && file.shortcutDetails.targetMimeType === mimeTypes.document)
    : false;

const patterns = {
  squareBrackets: /\[([^\]]+)\]/,
  isURL: /(https?:\/\/)?(www\.)?([-\w@:%.\\+~#=]{1,256}\.[a-z]{2,}|http:\/\/localhost:\d{2,})\b([-\w@:%\\+.~#?&/=]*)/,
  markdown: /_/,
};
patterns.markdown = new RegExp(`^${patterns.squareBrackets.source}(\\(${patterns.isURL.source}\\))$`);

/**
 * Matches the format [name](url)
 */
export function isMarkdownLinkTitle(name: string): ExternaLink | undefined {
  const isURL = patterns.markdown.exec(name);
  if (!isURL?.[1]) return undefined;

  const matchedURL = isURL[2]?.slice(1, isURL[2].length - 1).trim();
  if (!matchedURL) return undefined;

  const url = /^https?:\/\/|^mailto:/.test(matchedURL) ? matchedURL : `https://${matchedURL}`;

  return {
    name: isURL[1],
    url,
  };
}

export function isMarkdownLink(file: DriveFile | undefined): ExternaLink | undefined {
  if (!file?.name) return undefined;
  if (!isADocument(file)) return undefined;
  return isMarkdownLinkTitle(file.name);
}
