import { type DriveFile } from '~/utils/DriveAPI';
import { concat } from '~/utils/fast';
import { getId, isAFolder, isAShortcut } from '~/utils/utils';

const getAllChildren = (files: DriveFile[], parentId: string): DriveFile[] => {
  const children = files.filter((file) => file.parents?.[0] === parentId);
  const hasDuplicate = children.some((child) => isAShortcut(child));
  if (hasDuplicate) return children;

  return concat(
    children,
    children
      .filter((file) => isAFolder(file))
      .flatMap((file) =>
        getAllChildren(
          files.filter((file) => !children.some((child) => file.id === child.id)),
          getId(file)
        )
      )
  );
};

export default getAllChildren;
