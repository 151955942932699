import { Button } from '@mui/base/Button';
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import { Popover, Tooltip } from '@mui/material';
import cx from 'classnames';
import { bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import { useRef } from 'react';
import { tooltipSlotProps, tooltipSlots } from '~/elements/Tooltip';
import { darkTheme, lightTheme } from '~/theme.css';
import { isBun } from '~/utils/constants';
import { DriveFile } from '~/utils/DriveAPI';
import { useFile, useFiles, useKeyPress, useTheme, useWikis } from '~/utils/hooks';
import { getIndexPage, isAFolder } from '~/utils/utils';
import EditButton from '../EditButton/EditButton';
import Search from '../Search/Search';
import { ShareModal } from '../ShareModal/ShareModal';
import * as styles from './Header.css';

type DefaultProps = {
  folderId: string;
  id?: string | undefined;
};

export const HeaderWithData = ({ folderId, id = folderId }: DefaultProps) => {
  const { data: wikis = [] } = useWikis(folderId);
  const { data: files } = useFiles(folderId);
  const { data: wiki } = useFile(folderId);
  const { data: file } = useFile(id, files);
  return <Header file={file} files={files} wiki={wiki} wikis={wikis} />;
};

type Props = {
  file: DriveFile | undefined;
  files: DriveFile[];
  wiki: DriveFile | undefined;
  wikis: DriveFile[];
};

export const Header = ({ file, files, wiki, wikis }: Props) => {
  const viewButtonRef = useRef<HTMLAnchorElement>(null);
  const [theme, setTheme] = useTheme();
  const sharePopupState = usePopupState({ variant: 'popover', popupId: 'share' });
  const indexPage = file ? getIndexPage(file, files) : undefined;

  // Edit button
  useKeyPress('v', () => viewButtonRef.current?.click(), []);

  // Dark/light mode
  useKeyPress('d', () => setTheme(theme === 'light' ? 'dark' : 'light'), [theme]);

  return (
    <div className={styles.container}>
      {wiki && <Search files={files} wiki={wiki} />}

      <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', marginLeft: '.5rem' }}>
        <EditButton file={file} isIndexPage={!!indexPage} key={file?.id ?? Math.random()} />
        {file && (isAFolder(file) ? file.id : file?.parents?.[0]) && (
          <a
            ref={viewButtonRef}
            target="_blank"
            href={
              isAFolder(file)
                ? `https://drive.google.com/drive/folders/${file?.id}`
                : `https://drive.google.com/drive/folders/${file?.parents?.[0]}`
            }
          >
            View in Drive
          </a>
        )}
        <Button>Add new</Button>
        {file?.webViewLink && (
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              sharePopupState.setAnchorEl(e.currentTarget);
              sharePopupState.open();
            }}
          >
            Share
          </a>
        )}
        {wiki && (
          <Popover
            {...bindMenu(sharePopupState)}
            onClose={() => {
              sharePopupState.close();
            }}
            className={cx({
              [darkTheme]: theme === 'dark',
              [lightTheme]: theme === 'light',
            })}
            slotProps={{
              paper: {
                style: {
                  // maxWidth: 300,
                  marginTop: '1rem',
                  marginLeft: 2,
                  color: 'inherit',
                  boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 8px 0',
                  backgroundColor: 'var(--root-bg-color)',
                  borderRadius: 10,
                },
              },
            }}
            transitionDuration={{
              enter: isBun ? 0 : 200,
              exit: 0,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ShareModal
              onCancel={() => {
                sharePopupState.close();
              }}
              file={wiki}
              wiki={wiki}
              wikis={wikis}
              files={files}
              popover
            />
          </Popover>
        )}
        {file?.webViewLink && <span style={{ opacity: 0.3 }}>|</span>}
        <Tooltip
          slotProps={tooltipSlotProps}
          slots={tooltipSlots}
          title={theme === 'light' ? 'Switch to dark mode (d)' : 'Switch to light mode (d)'}
        >
          <Button
            style={{
              width: 34,
              height: 34,
            }}
            className={styles.buttonCircular}
            onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
          >
            {theme === 'light' ? (
              <LightModeOutlined style={{ fontSize: 22 }} />
            ) : (
              <DarkModeOutlined style={{ fontSize: 22 }} />
            )}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
