import { CircularProgress } from '@mui/material';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Route, useLocation, useRoute } from 'wouter';
import * as appStyles from './app.css';
import { HeaderWithData as Header } from './components/Header/Header';
import Page from './components/Page/Page';
import Sidebar from './components/Sidebar/Sidebar';
import { darkTheme, lightTheme } from './theme.css';
import { newFileId } from './utils/constants';
import gup from './utils/gup';
import { useAuth, useTheme } from './utils/hooks';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const route = useRoute('/app/page/:folderId/:id?')[1] ?? {
    id: undefined,
    folderId: undefined,
  };

  // support for legacy routes /app/page/:id?p=folderId
  const p = route.id ? undefined : gup('p');
  const params =
    route.id || !p || typeof p !== 'string'
      ? route
      : {
          id: route.folderId,
          folderId: p,
        };

  const { loaded: authLoaded, user } = useAuth();
  const [theme] = useTheme();
  const setLocation = useLocation()[1];

  useEffect(() => {
    gapi_loaded.promise.then(() => setLoading(false)).catch((err) => setError(err));
  }, []);

  // If user encounters a /new or /new-<id> route on load, redirect to the wiki as this is not a valid route
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run once
  useEffect(() => {
    if (params.id === newFileId || params.id?.startsWith(`${newFileId}-`)) {
      setLocation(`/app/page/${params.folderId}/${params.folderId}`, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        email: user.email,
        name: user.name,
      });
    }
  }, [user]);

  if (loading || !authLoaded) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  // const [theme, setTheme] = useTheme();
  //   <button
  //   type="button"
  //   onClick={() => {
  //     setTheme(theme === 'light' ? 'dark' : 'light');
  //   }}
  // >
  //   Toggle theme
  // </button>
  // Theme: {theme}

  // if (!user) {
  //   return (
  //     <div
  //       id="app"
  //       className={cx(appStyles.appContainer, {
  //         [darkTheme]: theme === 'dark',
  //         [lightTheme]: theme === 'light',
  //       })}
  //     >
  //       <h1>Please log in to access the wiki</h1>
  //       <br />
  //       <a href="/auth?consent">
  //         <button type="button">Log in</button>
  //       </a>
  //     </div>
  //   );
  // }

  return (
    <div
      id="app"
      className={cx(appStyles.appContainer, {
        [darkTheme]: theme === 'dark',
        [lightTheme]: theme === 'light',
      })}
    >
      <Route path="/app/page/:folderId/:id?">
        {params.folderId && <Sidebar folderId={params.folderId} id={params.id} />}
        <div
          style={{
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            maxWidth: '958px',
          }}
        >
          {params.folderId && <Header folderId={params.folderId} id={params.id} />}
          {params.folderId && <Page folderId={params.folderId} id={params.id} />}
        </div>
      </Route>
      <Route path="/app/settings">
        <h1>Wiki settings</h1>
      </Route>
    </div>
  );
};

export default App;
