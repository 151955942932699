import React from 'react';
import { match } from 'ts-pattern';
import * as buttonStyles from '~/elements/Buttons/Button.css';
import { Permission } from '~/utils/DriveAPI';
import { focusInput } from '~/utils/utils';
import * as treeStyles from '../Tree/Tree.css';
import * as styles from './FileModal.css';

export type PageModalType = 'document' | 'sheet' | 'slide' | 'form' | 'folder';
export type ModalType = 'rename' | 'trash' | 'copy' | 'link' | 'shortcut' | 'move' | PageModalType;

type BaseProps = {
  onCancel: (e: React.MouseEvent<HTMLButtonElement | HTMLOrSVGElement, MouseEvent>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string | undefined;
  onChangeURL?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title: string;
  description?: string | React.ReactNode;
  permissions?: Permission[] | undefined;
  style?: React.CSSProperties;
};

type Props = BaseProps &
  (
    | {
        modalType: 'link';
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        onChangeURL: (e: React.ChangeEvent<HTMLInputElement>) => void;
      }
    | {
        modalType: PageModalType;
        defaultValue: string | undefined;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      }
    | {
        modalType: 'rename';
        defaultValue: string | undefined;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      }
    | {
        modalType: 'copy' | 'trash';
        description: string | React.ReactNode;
      }
    | {
        modalType: 'share';
        permissions: Permission[] | undefined;
      }
  );

export const Title = ({ children, style }: { children: string | React.ReactNode; style?: React.CSSProperties }) => (
  <h2 className={treeStyles.modalTitle} style={style} id="modal-title">
    {children}
  </h2>
);

export const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
  // Submit form on enter if there are no inputs
  if (e.key === 'Enter' && e.target instanceof HTMLFormElement) {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.dispatchEvent(new Event('submit', { bubbles: true }));
  }
};

export const FileModal = ({
  title,
  modalType,
  onCancel,
  onSubmit,
  onChange,
  defaultValue,
  onChangeURL,
  description,
  style,
}: Props) => {
  const submitText = match(modalType)
    .with('rename', () => 'Save')
    .with('copy', () => 'Make a copy')
    .with('trash', () => 'Move to trash')
    .otherwise(() => 'Create');

  const ButtonCancel = () => (
    <button
      type="button"
      onClick={onCancel}
      style={{
        marginRight: 6,
        color: '#5f6368',
      }}
      className={buttonStyles.transparent}
    >
      Cancel
    </button>
  );

  const ButtonSubmit = (props: React.ButtonHTMLAttributes<HTMLButtonElement> = {}) => (
    <button type="submit" className={modalType === 'trash' ? buttonStyles.danger : buttonStyles.primary} {...props}>
      {submitText}
    </button>
  );

  if (modalType === 'link') {
    return (
      <form className={styles.modal} style={style} onSubmit={onSubmit} onKeyDown={onKeyDown} aria-label="form">
        <Title>{title}</Title>
        <label id="link-name" htmlFor="link-name">
          Name
        </label>
        &nbsp;<small>(optional)</small>
        <br />
        <input
          aria-labelledby="link-name"
          ref={focusInput}
          onChange={(e) => {
            onChange(e);
          }}
          className={treeStyles.modalInput}
        />
        <br />
        <br />
        <label id="link-url" htmlFor="link-url">
          Web address
        </label>
        <br />
        <input
          aria-labelledby="link-url"
          placeholder="example.com"
          onChange={(e) => {
            onChangeURL(e);
          }}
          className={treeStyles.modalInput}
        />
        <div
          style={{
            paddingTop: 34,
            textAlign: 'right',
          }}
        >
          <ButtonCancel />
          <ButtonSubmit />
        </div>
      </form>
    );
  }

  return (
    <form className={styles.modal} style={style} onSubmit={onSubmit} onKeyDown={onKeyDown} aria-label="form">
      <Title>{title}</Title>
      {onChange && (
        <input
          aria-labelledby="modal-title"
          ref={focusInput}
          defaultValue={defaultValue}
          onChange={(e) => {
            onChange?.(e);
          }}
          className={treeStyles.modalInput}
        />
      )}
      {/* Allows closing the modal via the keyboard */}
      {!onChange && (
        <input
          aria-labelledby="modal-title"
          ref={focusInput}
          style={{ opacity: 0, position: 'absolute', height: 0, width: 0 }}
        />
      )}
      {description ? <p>{description}</p> : null}
      <div
        style={{
          paddingTop: 34,
          textAlign: 'right',
        }}
      >
        <ButtonCancel />
        <ButtonSubmit />
      </div>
    </form>
  );
};
