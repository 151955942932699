import type { DriveFile, TreeFile } from '~/utils/DriveAPI';
import { getId, isAFolder, isAShortcut, omit } from '~/utils/utils';
import { createTree } from './createTree';
import { sort } from './sort';

/* c8 ignore start */
type PrevProps = {
  file: DriveFile | undefined;
  files: DriveFile[];
  wiki: DriveFile | undefined;
};

const getDeepestLastChild = (
  prevTreeNode: DriveFile,
  files: DriveFile[],
  flatTree: TreeFile[]
): TreeFile | undefined => {
  const children = flatTree.find((o) => o.id === prevTreeNode.id)?.children;
  const duplicate = isAShortcut(prevTreeNode) ? files.some((o) => o.id === getId(prevTreeNode)) : false;
  if (!children || children.length === 0 || duplicate) return files.find((o) => o.id === prevTreeNode.id);
  const sortedChildren = sort(children);
  const lastChild = sortedChildren.at(-1);
  if (!lastChild) return prevTreeNode;
  return getDeepestLastChild(lastChild, files, flatTree);
};

export const getPrev = ({ file, files: f, wiki }: PrevProps): DriveFile | undefined => {
  const files = f.filter((o) => o.name !== 'wiki.page');
  const isWiki = file?.id === wiki?.id;
  const siblingFiles = files.filter((o) => o.parents?.[0] === (isWiki ? wiki?.id : file?.parents?.[0]));
  const sortedSiblingFiles = sort(siblingFiles);
  const fileIndex = sortedSiblingFiles.findIndex((o) => o.id === file?.id);
  const prevNode = sortedSiblingFiles[fileIndex - 1];
  const prevNodeChildren = files.filter((o) => o.parents?.[0] === prevNode?.id);
  const sortedPrevNodeChildren = sort(prevNodeChildren);
  const flatTree = createTree(files, wiki?.id)?.[2];

  // If there's only a single file, we only show next
  if (files.length === 1) return isWiki ? undefined : wiki;

  // If there is no prev node, we return the last node of the previous folder or the parent
  if (!prevNode) {
    // If the current file is the wiki and the last sibling is not a folder
    if (isWiki && !isAFolder(sortedSiblingFiles.at(-1))) {
      return sortedSiblingFiles.at(-1);
    }

    // If this is first file in the folder, return the folder
    if (sortedSiblingFiles.at(0)?.id === file?.id) {
      const parent = files.find((o) => getId(o) === file?.parents?.[0]);
      if (!parent) return wiki;
      return parent;
    }

    // Get the previous folder and return it's last child
    const prevTreeNode = sort(flatTree.filter((o) => o.parents?.[0] === file?.id)).at(-1);
    if (prevTreeNode) {
      const deepestLastChild = getDeepestLastChild(prevTreeNode, files, flatTree);
      return deepestLastChild;
    }
    return undefined;
  }

  const prevTreeNode = flatTree.find((o) => o.id === prevNode.id);
  if (prevTreeNode && prevTreeNode?.children?.length !== 0) {
    const deepestLastChild = getDeepestLastChild(prevTreeNode, files, flatTree);
    return deepestLastChild;
  }

  if (sortedPrevNodeChildren.length === 0) {
    return prevNode;
  }
  if (!prevTreeNode) return sortedPrevNodeChildren.at(-1);

  const deepestLastChild = getDeepestLastChild(prevTreeNode, files, flatTree);

  return deepestLastChild ? (omit(deepestLastChild, 'children') as DriveFile) : sortedPrevNodeChildren.at(-1);
};

/* c8 ignore stop */
